@import 'styles/new-design/variables/_index';
@import 'styles/new-design/mixins/_index';

table {
  border-spacing: 0px;
}

table.pd-table:not([class*='default']),
table.mat-table:not([class*='default']) {
  border-spacing: 0;
  background: none;
}

table.mat-table thead tr,
table.pd-table thead tr,
tr.mat-header-row:not([class*='default']) {
  height: 3 * $spacing;
}

table.mat-table tbody tr,
table.mat-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-row:not([class*='default']),
tr.mat-footer-row:not([class*='default']) {
  height: 2.5 * $spacing;
}

table.mat-table thead th,
table.pd-table thead th,
th.mat-header-cell:not([class*='default']) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
  &:not([class*='text']) {
    text-align: left;
  }
}

table.mat-table thead th,
table.mat-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-header-cell:not([class*='default']),
td.mat-cell:not([class*='default']),
td.mat-footer-cell:not([class*='default']) {
  padding: 0.5 * $spacing;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $tableBorderColor;
  &:not([class*='white-space']) {
    white-space: nowrap;
  }
}

table.mat-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-table td,
table.pd-table td,
td.mat-cell:not([class*='default']),
td.mat-footer-cell:not([class*='default']) {
  color: mat-color($neutral);
}

table.mat-table tr th:first-child,
table.mat-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-header-cell:first-child:not([class*='default']),
td.mat-cell:first-child:not([class*='default']),
td.mat-footer-cell:first-child:not([class*='default']) {
  padding-left: 1 * $spacing;
}

table.mat-table th:last-child,
table.mat-table td:last-child,
table.pd-table th:last-child,
table.pd-table td:last-child {
  &:not([class*='default']) {
    padding-right: 1 * $spacing;
    white-space: nowrap;
  }
}

table.mat-table tbody tr:last-child,
table.pd-table tbody tr:last-child,
tr.mat-row:last-child:not([class*='default']),
tr.mat-header-row:last-child:not([class*='default']),
tr.mat-footer-row:last-child:not([class*='default']) {
  td {
    border-width: 0;
  }
}

tr.mat-footer-row:last-child td.mat-table-sticky:not([class*='default']),
tr.mat-footer-row:last-child td.pd-table-sticky:not([class*='default']) {
  border-width: 1px 0 0;
}
