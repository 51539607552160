@import 'styles/new-design/variables/_index';
@import 'styles/new-design/mixins/_index';
@import 'styles/new-design/base/layout';

$stepperHeaderHeight: 3 * $spacing;

.mat-stepper-horizontal:not([class*='default']),
.mat-stepper-vertical:not([class*='default']) {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $bodyBackground;
}

.mat-horizontal-stepper-header-container {
  flex: 0 0 auto;
}

.mat-horizontal-stepper-header:not([class*='default']) {
  flex: 1;
  height: $stepperHeaderHeight;
  justify-content: center;
  padding: 0;
  overflow: visible;
}

.mat-horizontal-content-container:not([class*='default']) {
  @include page-spacing();
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  flex: 1 1 0;
}

// this is used to position stepper-sidebar
$h2StepperMarginTop: 2 * $spacing;
.mat-horizontal-stepper-content:not([class*='default']) {
  @include page-spacing();
  box-sizing: border-box;
  transition: {
    duration: $swift-ease-in-out-duration;
    timing-function: $swift-ease-in-out-timing-function;
    property: padding-right;
  }

  h2 {
    margin-top: $h2StepperMarginTop;
  }

  height: 100%;
  &:not(:empty)::after {
    content: '';
    height: $pageBottomSpacing;
    display: block;
    width: 100%;
  }
}

.mat-horizontal-stepper-content[aria-expanded='false'] {
  padding: 0;
}

.mat-step-optional {
  display: none;
}

@mixin active-step($color: $neutral) {
  cursor: pointer;
  font-weight: unset;
  @include inclined-gradient($color);

  &::before {
    border-top: 0.5 * $stepperHeaderHeight solid mat-color($color, 900);
    border-bottom: 0.5 * $stepperHeaderHeight solid mat-color($color, 900);
  }

  @include media-lt-md {
    @include gradient($color, 900, 700);
    &::before {
      display: none;
    }
  }
}

.mat-step-header .mat-step-label:not([class*='default']) {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: mat-color($neutral, default-contrast);
  @include inclined-gradient($neutral, 400, 300);
  overflow: visible;
  cursor: default;

  &::before {
    content: '';
    position: absolute;
    left: -0.5 * $spacing;
    width: 0;
    height: 0;
    border-top: 0.5 * $stepperHeaderHeight solid mat-color($neutral, 400);
    border-bottom: 0.5 * $stepperHeaderHeight solid mat-color($neutral, 400);
    border-left: 0.5 * $spacing solid transparent;
  }

  &.mat-step-label-active {
    @include active-step();
  }

  &.mat-step-label-selected {
    @include active-step($primary);
  }
}

.mat-horizontal-stepper-header:first-of-type .mat-step-label::before {
  display: none;
}

.mat-step-icon:not([class*='default']) {
  display: none;
}

.mat-stepper-horizontal-line:not([class*='default']) {
  flex: 0;
  min-width: 0;
  margin: 0;
}
