@import 'styles/new-design/variables/_index';

.mat-card:not([class*='default']) {
  box-sizing: border-box;
  padding: 1 * $spacing;
}

.mat-card .mat-card-image {
  width: calc(100% + #{2 * $spacing});
  margin: 0 -1 * $spacing 1 * $spacing -1 * $spacing;
}

$buttonSidePadding: 2 * $spacing;

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-stroked-button.mat-button-base,
.mat-raised-button.mat-button-base {
  line-height: 2 * $spacing;
  padding: 0 $buttonSidePadding;
}

.mat-stroked-button.mat-button-base:not([class*='default']) {
  padding: 0 calc(#{2 * $spacing} - 1px);
}
