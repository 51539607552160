@import 'styles/new-design/variables/_index';
.loading {
  &.mat-progress-bar {
    bottom: 0.2 * $spacing;
    margin-bottom: -0.2 * $spacing;
  }
  .mat-progress-bar-buffer {
    background-color: mat-color($info, 100);
  }
  .mat-progress-bar-fill::after {
    background-color: mat-color($info, 600);
  }
}
