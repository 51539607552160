.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.alternate-surface,
mat-card.alternate-surface,
mat-expansion-panel.alternate-surface {
  background: #ecedee !important;
}

.mat-ripple-element {
  background-color: rgba(48, 60, 66, 0.1);
}

.mat-option {
  color: #303c42;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #303c42;
}
.mat-option.mat-option-disabled {
  color: #303c42;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c91019;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5d6a72;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e67300;
}

.mat-optgroup-label {
  color: #303c42;
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: #303c42;
}

.mat-pseudo-checkbox {
  color: #303c42;
}
.mat-pseudo-checkbox::after {
  color: #ecedee;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #c91019;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5d6a72;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e67300;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #ecedee;
  color: #303c42;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: #303c42;
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #303c42;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #c91019;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #5d6a72;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #e67300;
}

.mat-badge-disabled .mat-badge-content {
  background: #5d6a72;
  color: rgba(93, 106, 114, 0.8);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #303c42;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #c91019;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #5d6a72;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e67300;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #5d6a72;
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #c91019;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #5d6a72;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e67300;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #303c42;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #aeb5b9;
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: #303c42;
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #5d6a72;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #c91019;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #5d6a72;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #e67300;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: #303c42;
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(48, 60, 66, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: #303c42;
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #303c42;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #aeb5b9;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #aeb5b9;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #aeb5b9;
}

.mat-button-toggle-checked {
  background-color: #8e979c;
  color: #303c42;
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #303c42;
}

.mat-button-toggle-disabled {
  color: #5d6a72;
  background-color: #aeb5b9;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #758087;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #aeb5b9;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: #303c42;
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: #303c42;
}

.mat-checkbox-frame {
  border-color: #303c42;
}

.mat-checkbox-checkmark {
  fill: #ecedee;
}

.mat-checkbox-checkmark-path {
  stroke: #ecedee !important;
}

.mat-checkbox-mixedmark {
  background-color: #ecedee;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #c91019;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5d6a72;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e67300;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: #303c42;
}

.mat-checkbox .mat-ripple-element {
  background-color: #303c42;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #c91019;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #5d6a72;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e67300;
}

.mat-chip.mat-standard-chip {
  background-color: #8e979c;
  color: #303c42;
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #303c42;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #303c42;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #c91019;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e67300;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #5d6a72;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: #aeb5b9;
}

.mat-header-cell {
  color: #303c42;
}

.mat-cell, .mat-footer-cell {
  color: #303c42;
}

.mat-calendar-arrow {
  fill: #303c42;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #303c42;
}

.mat-calendar-table-header-divider::after {
  background: #aeb5b9;
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: #303c42;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #303c42;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(93, 106, 114, 0.8);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(93, 106, 114, 0.8);
}

.mat-calendar-body-in-preview {
  color: #aeb5b9;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #303c42;
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(48, 60, 66, 0.8);
}

.mat-calendar-body-in-range::before {
  background: rgba(201, 16, 25, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(201, 16, 25, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(201, 16, 25, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #c91019;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(201, 16, 25, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(201, 16, 25, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(201, 16, 25, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #303c42;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(93, 106, 114, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 106, 114, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 106, 114, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5d6a72;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 106, 114, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 106, 114, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(93, 106, 114, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(230, 115, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(230, 115, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(230, 115, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e67300;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(230, 115, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(230, 115, 0, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(230, 115, 0, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #c91019;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #5d6a72;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #e67300;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(93, 106, 114, 0.8);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: #303c42;
}

.mat-divider {
  border-top-color: #aeb5b9;
}

.mat-divider-vertical {
  border-right-color: #aeb5b9;
}

.mat-expansion-panel {
  background: white;
  color: #303c42;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: #aeb5b9;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: #303c42;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #303c42;
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: #5d6a72;
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(48, 60, 66, 0.6);
}

.mat-hint {
  color: rgba(48, 60, 66, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #c91019;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #5d6a72;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e67300;
}

.mat-focused .mat-form-field-required-marker {
  color: #5d6a72;
}

.mat-form-field-ripple {
  background-color: rgba(174, 181, 185, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #c91019;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #5d6a72;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e67300;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #c91019;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #5d6a72;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e67300;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e67300;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e67300;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e67300;
}

.mat-error {
  color: #e67300;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #303c42;
}
.mat-form-field-appearance-legacy .mat-hint {
  color: #303c42;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(174, 181, 185, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(174, 181, 185, 0.42) 0%, rgba(174, 181, 185, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(174, 181, 185, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(174, 181, 185, 0.42) 0%, rgba(174, 181, 185, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(48, 60, 66, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(48, 60, 66, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(174, 181, 185, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(93, 106, 114, 0.8);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(174, 181, 185, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(174, 181, 185, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #c91019;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #5d6a72;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e67300;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e67300;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(93, 106, 114, 0.8);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(174, 181, 185, 0.06);
}

.mat-icon.mat-primary {
  color: #c91019;
}
.mat-icon.mat-accent {
  color: #5d6a72;
}
.mat-icon.mat-warn {
  color: #e67300;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #303c42;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(93, 106, 114, 0.8);
}

.mat-input-element {
  caret-color: #c91019;
}
.mat-input-element::placeholder {
  color: rgba(48, 60, 66, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(48, 60, 66, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(48, 60, 66, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(48, 60, 66, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #5d6a72;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #e67300;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e67300;
}

.mat-list-base .mat-list-item {
  color: #303c42;
}
.mat-list-base .mat-list-option {
  color: #303c42;
}
.mat-list-base .mat-subheader {
  color: #303c42;
}
.mat-list-base .mat-list-item-disabled {
  background-color: #aeb5b9;
  color: rgba(93, 106, 114, 0.8);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: #303c42;
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(48, 60, 66, 0.8);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: #303c42;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #303c42;
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid #303c42;
  border-right: 2px solid #303c42;
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid #303c42;
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(48, 60, 66, 0.8);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #e3b6b9;
}

.mat-progress-bar-buffer {
  background-color: #e3b6b9;
}

.mat-progress-bar-fill::after {
  background-color: #c91019;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c8cccf;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c8cccf;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #5d6a72;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ebcfb3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ebcfb3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e67300;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #c91019;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #5d6a72;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #e67300;
}

.mat-radio-outer-circle {
  border-color: #303c42;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c91019;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #c91019;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5d6a72;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5d6a72;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e67300;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e67300;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(48, 60, 66, 0.8);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(48, 60, 66, 0.8);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(48, 60, 66, 0.8);
}
.mat-radio-button .mat-ripple-element {
  background-color: #303c42;
}

.mat-select-value {
  color: #303c42;
}

.mat-select-placeholder {
  color: rgba(48, 60, 66, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(93, 106, 114, 0.8);
}

.mat-select-arrow {
  color: #303c42;
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #c91019;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #5d6a72;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e67300;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e67300;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(93, 106, 114, 0.8);
}

.mat-drawer-container {
  background-color: #ecedee;
  color: #303c42;
}

.mat-drawer {
  background-color: white;
  color: #303c42;
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px #aeb5b9;
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #aeb5b9;
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px #aeb5b9;
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #aeb5b9;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5d6a72;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(93, 106, 114, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #5d6a72;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #c91019;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(201, 16, 25, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #c91019;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e67300;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(230, 115, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e67300;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #303c42;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(48, 60, 66, 0.8);
}

.mat-slider-track-background {
  background-color: #8e979c;
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #c91019;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(201, 16, 25, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #5d6a72;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(93, 106, 114, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e67300;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(230, 115, 0, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #758087;
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: #8e979c;
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #8e979c;
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(48, 60, 66, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #303c42;
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #8e979c;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #8e979c;
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #758087;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #8e979c;
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(48, 60, 66, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(48, 60, 66, 0.7), rgba(48, 60, 66, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(48, 60, 66, 0.7), rgba(48, 60, 66, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(48, 60, 66, 0.7), rgba(48, 60, 66, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: #303c42;
}
.mat-step-header .mat-step-icon {
  background-color: #303c42;
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #c91019;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5d6a72;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e67300;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e67300;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: #303c42;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e67300;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: #aeb5b9;
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: #aeb5b9;
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #303c42;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid #aeb5b9;
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #aeb5b9;
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: #303c42;
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(93, 106, 114, 0.8);
}

.mat-tab-header-pagination-chevron {
  border-color: #303c42;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(93, 106, 114, 0.8);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(239, 183, 186, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #c91019;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(206, 210, 213, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #5d6a72;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 213, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e67300;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(239, 183, 186, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #c91019;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(206, 210, 213, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #5d6a72;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 213, 179, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e67300;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: #758087;
  color: #303c42;
}
.mat-toolbar.mat-primary {
  background: #c91019;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #5d6a72;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #e67300;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: #303c42;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #5d6a72;
}

@font-face {
  font-family: PalfingerIcons;
  src: url("/assets/fonts/PalfingerIcons.eot");
  src: url("/assets/fonts/PalfingerIcons.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PalfingerIcons.woff2") format("woff2"), url("/assets/fonts/PalfingerIcons.woff") format("woff"), url("/assets/fonts/PalfingerIcons.ttf") format("truetype"), url("/assets/fonts/PalfingerIcons.svg#PalfingerIcons") format("svg");
  font-style: normal;
  font-weight: 400;
}
.pd {
  display: inline-block;
  transform: translate(0, 0);
  text-rendering: auto;
  font: normal normal 400 14px/1 PalfingerIcons;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.pd-lg {
  vertical-align: -15%;
  line-height: 0.75em;
  font-size: 1.33333333em;
}

.pd-2x {
  font-size: 2em;
}

.pd-3x {
  font-size: 3em;
}

.pd-4x {
  font-size: 4em;
}

.pd-5x {
  font-size: 5em;
}

.pd-fw {
  width: 1.28571429em;
  text-align: center;
}

.pd-access-denied::before {
  content: "\ea01";
}

.pd-alerts::before {
  content: "\ea02";
}

.pd-axis-y-rotate::before {
  content: "\ea03";
}

.pd-axis-z-rotate::before {
  content: "\ea04";
}

.pd-carrier::before {
  content: "\ea05";
}

.pd-carrier-management::before {
  content: "\ea06";
}

.pd-code-reader::before {
  content: "\ea07";
}

.pd-crane-functions::before {
  content: "\ea08";
}

.pd-crane-lifting-power::before {
  content: "\ea09";
}

.pd-crane-outreach::before {
  content: "\ea0a";
}

.pd-documents::before {
  content: "\ea0b";
}

.pd-engine::before {
  content: "\ea0c";
}

.pd-equipment-status::before {
  content: "\ea0d";
}

.pd-filter::before {
  content: "\ea0e";
}

.pd-fleet-monitor::before {
  content: "\ea0f";
}

.pd-fleet-status::before {
  content: "\ea10";
}

.pd-geofence::before {
  content: "\ea11";
}

.pd-height-limitation::before {
  content: "\ea12";
}

.pd-job-planning::before {
  content: "\ea13";
}

.pd-local_shipping_outlined::before {
  content: "\ea14";
}

.pd-location::before {
  content: "\ea15";
}

.pd-location-status::before {
  content: "\ea16";
}

.pd-lubricant-1::before {
  content: "\ea17";
}

.pd-lubricant-2::before {
  content: "\ea18";
}

.pd-lubricant-3::before {
  content: "\ea19";
}

.pd-lubricant-4::before {
  content: "\ea1a";
}

.pd-lubricant-5::before {
  content: "\ea1b";
}

.pd-mail-read-and-prio::before {
  content: "\ea1c";
}

.pd-maintenance-checklist::before {
  content: "\ea1d";
}

.pd-market-model::before {
  content: "\ea1e";
}

.pd-no-search-result::before {
  content: "\ea1f";
}

.pd-oh-crane::before {
  content: "\ea20";
}

.pd-oh-dailyPTO::before {
  content: "\ea21";
}

.pd-oh-emergencyModeActivation::before {
  content: "\ea22";
}

.pd-oh-flyjib::before {
  content: "\ea23";
}

.pd-oh-hookloader::before {
  content: "\ea24";
}

.pd-oh-leverNotNeutral::before {
  content: "\ea25";
}

.pd-oh-loadingOperations::before {
  content: "\ea26";
}

.pd-oh-manualOverride::before {
  content: "\ea27";
}

.pd-oh-mechv::before {
  content: "\ea28";
}

.pd-oh-platforms::before {
  content: "\ea29";
}

.pd-oh-taillift::before {
  content: "\ea2a";
}

.pd-oh-tippingOperations::before {
  content: "\ea2b";
}

.pd-oh-tool::before {
  content: "\ea2c";
}

.pd-oh-winch::before {
  content: "\ea2d";
}

.pd-oh-winch_rtc::before {
  content: "\ea2e";
}

.pd-oh-workingBasket::before {
  content: "\ea2f";
}

.pd-oh-workingBasket_leverNotNeutral::before {
  content: "\ea30";
}

.pd-pac-online::before {
  content: "\ea31";
}

.pd-palcode::before {
  content: "\ea32";
}

.pd-palshow-web::before {
  content: "\ea33";
}

.pd-palsoft-updates::before {
  content: "\ea34";
}

.pd-route::before {
  content: "\ea35";
}

.pd-sentiment-dissatisfied::before {
  content: "\ea36";
}

.pd-sentiment-satisfied::before {
  content: "\ea37";
}

.pd-service::before {
  content: "\ea38";
}

.pd-service-cockpit::before {
  content: "\ea39";
}

.pd-service-partner-search::before {
  content: "\ea3a";
}

.pd-software-options::before {
  content: "\ea3b";
}

.pd-stability-chart::before {
  content: "\ea3c";
}

.pd-stabilizer-left::before {
  content: "\ea3d";
}

.pd-stabilizer-left-disabled::before {
  content: "\ea3e";
}

.pd-stabilizer-right::before {
  content: "\ea3f";
}

.pd-stabilizer-right-disabled::before {
  content: "\ea40";
}

.pd-telematic::before {
  content: "\ea41";
}

.pd-telematic-doctor::before {
  content: "\ea42";
}

.pd-telematic-lab::before {
  content: "\ea43";
}

.pd-utilization::before {
  content: "\ea44";
}

.pd {
  text-align: center;
  font-size: 24px;
}

/* roboto-100 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto Regular"), local("Roboto-Regular"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-900 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Black"), local("Roboto-Black"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "PalfingerHandelVersalien";
  src: url("/assets/fonts/palfingerhandelversalien-webfont.woff2") format("woff2"), url("/assets/fonts/palfingerhandelversalien-webfont.woff") format("woff");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("/assets/materialicons/MaterialIcons-Regular.woff2") format("woff2"), url("/assets/materialicons/MaterialIcons-Regular.woff") format("woff"), url("/assets/materialicons/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons, .mat-select-arrow::before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.palfinger-font:not([class*=default]) {
  font-family: PalfingerHandelVersalien;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #303c42;
  font-size: 0.875rem;
  line-height: 1.57;
}

a {
  color: #c91019;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

h1:not([class*=default]),
.h1,
h2:not([class*=default]),
.h2,
h3:not([class*=default]),
.h3,
h4:not([class*=default]),
.h4,
h5:not([class*=default]),
.h5,
h6:not([class*=default]),
.h6 {
  line-height: 1;
}

h1:not([class*=default]),
.h1 {
  font-size: 1.875rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
.h1 {
    font-size: 1.5;
    font-weight: 400;
  }
}

h2:not([class*=default]),
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h2:not([class*=default]),
.h2 {
    font-size: 1.5rem;
  }
}

h3:not([class*=default]),
.h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  h3:not([class*=default]),
.h3 {
    font-size: 1rem;
  }
}

h4:not([class*=default]),
.h4 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h4:not([class*=default]),
.h4 {
    font-size: 0.875rem;
  }
}

h5:not([class*=default]),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;
}
@media screen and (max-width: 599px) {
  h5:not([class*=default]),
.h5 {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*=default]),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: #5d6a72;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #4b575f;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*=default]),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 500;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #f8f9fb;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
  padding: 5px;
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

.mat-drawer {
  border-right: none;
}

.mat-drawer-container {
  background: none;
  color: inherit;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
  /* stylelint-disable-line function-allowed-list */
}

.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-card:not([class*=mat-elevation-z]),
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  /* stylelint-disable-line function-allowed-list */
}

.mat-fab:not([class*=mat-elevation-z]),
.mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]),
.mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.22);
  /* stylelint-disable-line function-allowed-list */
}

.mat-snack-bar-container:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z0 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.02);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z1 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z2 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.04);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z3 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z4 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.06);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z5 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.07);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z6 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.08);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z7 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.09);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z8 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z9 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.11);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z10 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z11 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.13);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z12 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.14);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z13 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.15);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z14 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.16);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z15 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.17);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z16 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.18);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z17 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.19);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z18 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.2);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z19 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.21);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.22);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.23);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.24);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z23 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.25);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z24 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.26);
  /* stylelint-disable-line function-allowed-list */
}

.mat-card:not([class*=default]) {
  box-sizing: border-box;
  padding: 20px;
}

.mat-card .mat-card-image {
  width: calc(100% + 40px);
  margin: 0 -20px 20px -20px;
}

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-stroked-button.mat-button-base,
.mat-raised-button.mat-button-base {
  line-height: 40px;
  padding: 0 40px;
}

.mat-stroked-button.mat-button-base:not([class*=default]) {
  padding: 0 calc(40px - 1px);
}

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-raised-button.mat-button-base,
.mat-stroked-button.mat-button-base {
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-transform: none;
  border-radius: 2px;
}
.mat-button.mat-button-base[disabled],
.mat-flat-button.mat-button-base[disabled],
.mat-raised-button.mat-button-base[disabled],
.mat-stroked-button.mat-button-base[disabled] {
  opacity: 0.8;
}
.mat-button.mat-button-base mat-spinner,
.mat-flat-button.mat-button-base mat-spinner,
.mat-raised-button.mat-button-base mat-spinner,
.mat-stroked-button.mat-button-base mat-spinner {
  display: inline-block;
}

.mat-raised-button.mat-accent:focus,
.mat-raised-button.mat-accent:hover {
  transition: none;
  background: #55626a;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: #303c42;
}

.mat-stroked-button.mat-button-base {
  border-color: inherit;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}
.mat-flat-button.mat-accent mat-spinner circle,
.mat-raised-button.mat-accent mat-spinner circle,
.mat-fab.mat-accent mat-spinner circle,
.mat-mini-fab.mat-accent mat-spinner circle {
  stroke: white;
}
.mat-flat-button.mat-accent[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-fab.mat-accent[disabled],
.mat-mini-fab.mat-accent[disabled] {
  color: white;
  opacity: 0.4;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}
.mat-flat-button.mat-primary mat-spinner circle,
.mat-raised-button.mat-primary mat-spinner circle,
.mat-fab.mat-primary mat-spinner circle,
.mat-mini-fab.mat-primary mat-spinner circle {
  stroke: white;
}
.mat-flat-button.mat-primary[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-fab.mat-primary[disabled],
.mat-mini-fab.mat-primary[disabled] {
  color: white;
  opacity: 0.4;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background: linear-gradient(75deg, #d54300, #df6000);
}
@media print {
  .mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
    background: linear-gradient(75deg, #d54300, #df6000);
    -webkit-print-color-adjust: exact;
  }
}
.mat-flat-button.mat-warn mat-spinner circle,
.mat-raised-button.mat-warn mat-spinner circle,
.mat-fab.mat-warn mat-spinner circle,
.mat-mini-fab.mat-warn mat-spinner circle {
  stroke: white;
}
.mat-flat-button.mat-warn[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-fab.mat-warn[disabled],
.mat-mini-fab.mat-warn[disabled] {
  color: white;
  opacity: 0.4;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #f8f9fb;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
  padding: 5px;
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
.mat-dialog-container:not([class*=default]) {
  border-radius: 2px;
  padding: 20px;
  background: #f8f9fb;
}

.mat-dialog-content:not([class*=default]) {
  margin: 0 -20px;
  padding: 0 20px 20px;
}

.mat-dialog-title:not([class*=default]) {
  margin: 0 0 20px !important;
  display: block;
}

.mat-dialog-actions:not([class*=default]) {
  background: #ecedee;
  padding: 10px 20px !important;
  margin: -20px;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.mat-dialog-actions:not([class*=default]) .mat-button-base + .mat-button-base {
  margin-left: 10px;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
table {
  border-spacing: 0px;
}

table.pd-table:not([class*=default]),
table.mat-table:not([class*=default]) {
  border-spacing: 0;
  background: none;
}

table.mat-table thead tr,
table.pd-table thead tr,
tr.mat-header-row:not([class*=default]) {
  height: 60px;
}

table.mat-table tbody tr,
table.mat-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-row:not([class*=default]),
tr.mat-footer-row:not([class*=default]) {
  height: 50px;
}

table.mat-table thead th,
table.pd-table thead th,
th.mat-header-cell:not([class*=default]) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
}
table.mat-table thead th:not([class*=text]),
table.pd-table thead th:not([class*=text]),
th.mat-header-cell:not([class*=default]):not([class*=text]) {
  text-align: left;
}

table.mat-table thead th,
table.mat-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-header-cell:not([class*=default]),
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  padding: 10px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #ecedee;
}
table.mat-table thead th:not([class*=white-space]),
table.mat-table td:not([class*=white-space]),
table.pd-table thead th:not([class*=white-space]),
table.pd-table td:not([class*=white-space]),
th.mat-header-cell:not([class*=default]):not([class*=white-space]),
td.mat-cell:not([class*=default]):not([class*=white-space]),
td.mat-footer-cell:not([class*=default]):not([class*=white-space]) {
  white-space: nowrap;
}

table.mat-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-table td,
table.pd-table td,
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  color: #5d6a72;
}

table.mat-table tr th:first-child,
table.mat-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-header-cell:first-child:not([class*=default]),
td.mat-cell:first-child:not([class*=default]),
td.mat-footer-cell:first-child:not([class*=default]) {
  padding-left: 20px;
}

table.mat-table th:last-child:not([class*=default]),
table.mat-table td:last-child:not([class*=default]),
table.pd-table th:last-child:not([class*=default]),
table.pd-table td:last-child:not([class*=default]) {
  padding-right: 20px;
  white-space: nowrap;
}

table.mat-table tbody tr:last-child td,
table.pd-table tbody tr:last-child td,
tr.mat-row:last-child:not([class*=default]) td,
tr.mat-header-row:last-child:not([class*=default]) td,
tr.mat-footer-row:last-child:not([class*=default]) td {
  border-width: 0;
}

tr.mat-footer-row:last-child td.mat-table-sticky:not([class*=default]),
tr.mat-footer-row:last-child td.pd-table-sticky:not([class*=default]) {
  border-width: 1px 0 0;
}

.mat-list-base .mat-list-item {
  font-size: inherit;
}

.mat-list-base {
  padding-top: 20px;
}

.mat-list-base .mat-list-item:not([class*=default]),
.mat-list-base .mat-list-option:not([class*=default]) {
  height: 40px;
}

.mat-list-base .mat-list-item .mat-list-item-content:not([class*=default]),
.mat-list-base .mat-list-option .mat-list-item-content:not([class*=default]) {
  padding: 0 20px;
}

.mat-select-arrow:not([class*=default]) {
  border: none;
  margin-bottom: -12px;
  margin-top: -12px;
  height: auto;
  width: auto;
}

.mat-select-arrow::before {
  content: "expand_more";
}

.mat-select-search-inside-mat-option:not([class*=default]) button.mat-select-search-clear:not([class*=default]) {
  padding: 0;
  border-radius: 50%;
  top: 1px;
}

.mat-select-panel:not([class*=default]) {
  max-width: 80%;
}

::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: unset;
}

::ng-deep .mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: #55626a;
}

.mat-chip.mat-standard-chip {
  color: white;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #f8f9fb;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
  padding: 5px;
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

.mat-stepper-horizontal:not([class*=default]),
.mat-stepper-vertical:not([class*=default]) {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f8f9fb;
}

.mat-horizontal-stepper-header-container {
  flex: 0 0 auto;
}

.mat-horizontal-stepper-header:not([class*=default]) {
  flex: 1;
  height: 60px;
  justify-content: center;
  padding: 0;
  overflow: visible;
}

.mat-horizontal-content-container:not([class*=default]) {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  flex: 1 1 0;
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .mat-horizontal-content-container:not([class*=default]) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 599px) {
  .mat-horizontal-content-container:not([class*=default]) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.mat-horizontal-stepper-content:not([class*=default]) {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  transition-property: padding-right;
  height: 100%;
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .mat-horizontal-stepper-content:not([class*=default]) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 599px) {
  .mat-horizontal-stepper-content:not([class*=default]) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mat-horizontal-stepper-content:not([class*=default]) h2 {
  margin-top: 40px;
}
.mat-horizontal-stepper-content:not([class*=default]):not(:empty)::after {
  content: "";
  height: 40px;
  display: block;
  width: 100%;
}

.mat-horizontal-stepper-content[aria-expanded=false] {
  padding: 0;
}

.mat-step-optional {
  display: none;
}

.mat-step-header .mat-step-label:not([class*=default]) {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(75deg, #758087, #8e979c);
  overflow: visible;
  cursor: default;
}
@media print {
  .mat-step-header .mat-step-label:not([class*=default]) {
    background: linear-gradient(75deg, #758087, #8e979c);
    -webkit-print-color-adjust: exact;
  }
}
.mat-step-header .mat-step-label:not([class*=default])::before {
  content: "";
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 30px solid #758087;
  border-bottom: 30px solid #758087;
  border-left: 10px solid transparent;
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
  cursor: pointer;
  font-weight: unset;
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before {
  border-top: 30px solid #303c42;
  border-bottom: 30px solid #303c42;
}
@media screen and (max-width: 959px) {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
    background: linear-gradient(180deg, #303c42, #4b575f);
  }
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before {
    display: none;
  }
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
  cursor: pointer;
  font-weight: unset;
  background: linear-gradient(75deg, #a90508, #bc0c12);
}
@media print {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
    background: linear-gradient(75deg, #a90508, #bc0c12);
    -webkit-print-color-adjust: exact;
  }
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before {
  border-top: 30px solid #a90508;
  border-bottom: 30px solid #a90508;
}
@media screen and (max-width: 959px) {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
    background: linear-gradient(180deg, #a90508, #bc0c12);
  }
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before {
    display: none;
  }
}

.mat-horizontal-stepper-header:first-of-type .mat-step-label::before {
  display: none;
}

.mat-step-icon:not([class*=default]) {
  display: none;
}

.mat-stepper-horizontal-line:not([class*=default]) {
  flex: 0;
  min-width: 0;
  margin: 0;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
table {
  border-spacing: 0px;
}

table.pd-table:not([class*=default]),
table.mat-table:not([class*=default]) {
  border-spacing: 0;
  background: none;
}

table.mat-table thead tr,
table.pd-table thead tr,
tr.mat-header-row:not([class*=default]) {
  height: 60px;
}

table.mat-table tbody tr,
table.mat-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-row:not([class*=default]),
tr.mat-footer-row:not([class*=default]) {
  height: 50px;
}

table.mat-table thead th,
table.pd-table thead th,
th.mat-header-cell:not([class*=default]) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
}
table.mat-table thead th:not([class*=text]),
table.pd-table thead th:not([class*=text]),
th.mat-header-cell:not([class*=default]):not([class*=text]) {
  text-align: left;
}

table.mat-table thead th,
table.mat-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-header-cell:not([class*=default]),
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  padding: 10px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #ecedee;
}
table.mat-table thead th:not([class*=white-space]),
table.mat-table td:not([class*=white-space]),
table.pd-table thead th:not([class*=white-space]),
table.pd-table td:not([class*=white-space]),
th.mat-header-cell:not([class*=default]):not([class*=white-space]),
td.mat-cell:not([class*=default]):not([class*=white-space]),
td.mat-footer-cell:not([class*=default]):not([class*=white-space]) {
  white-space: nowrap;
}

table.mat-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-table td,
table.pd-table td,
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  color: #5d6a72;
}

table.mat-table tr th:first-child,
table.mat-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-header-cell:first-child:not([class*=default]),
td.mat-cell:first-child:not([class*=default]),
td.mat-footer-cell:first-child:not([class*=default]) {
  padding-left: 20px;
}

table.mat-table th:last-child:not([class*=default]),
table.mat-table td:last-child:not([class*=default]),
table.pd-table th:last-child:not([class*=default]),
table.pd-table td:last-child:not([class*=default]) {
  padding-right: 20px;
  white-space: nowrap;
}

table.mat-table tbody tr:last-child td,
table.pd-table tbody tr:last-child td,
tr.mat-row:last-child:not([class*=default]) td,
tr.mat-header-row:last-child:not([class*=default]) td,
tr.mat-footer-row:last-child:not([class*=default]) td {
  border-width: 0;
}

tr.mat-footer-row:last-child td.mat-table-sticky:not([class*=default]),
tr.mat-footer-row:last-child td.pd-table-sticky:not([class*=default]) {
  border-width: 1px 0 0;
}

.mat-expansion-panel:not([class*=default]) {
  border-bottom: 1px solid #ecedee;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header {
  font-size: inherit;
  padding: 1px 20px;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header.mat-expanded {
  padding: 3px 20px;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header .mat-content {
  margin-right: 20px;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-body {
  padding: 0 20px 20px;
}

.mat-expansion-panel:last-of-type,
.mat-expansion-panel.mat-expanded {
  border-bottom: 0;
}

mat-card .mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
  border-bottom: 1px solid #ecedee;
}
mat-card .mat-accordion .mat-expansion-panel:first-of-type {
  border-top: 1px solid #ecedee;
}
mat-card .mat-accordion .mat-expansion-panel {
  border-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
.mat-snack-bar-container:not([class*=default]) {
  position: absolute;
  background: white;
  border-radius: 2px;
  color: unset;
  max-width: calc(100% - 56px) !important;
  margin: 0;
  bottom: 20px;
  left: 80px;
}
.mat-snack-bar-container:not([class*=default]) ds-text-indicator {
  overflow: hidden;
}
.mat-snack-bar-container:not([class*=default]) ds-text-indicator span {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 599px) {
  .mat-snack-bar-container:not([class*=default]) {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }
}

.mat-snack-bar-container.snackbar-with-component {
  padding: 0;
}

.mat-form-field-wrapper:not([class*=default]) {
  padding-bottom: 20px;
}

.pd-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper,
.mat-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper {
  margin-top: 1em;
}
.pd-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper,
.mat-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  padding: 10px 0;
  margin: 0;
}

.form-grid .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper,
ui-kit-table-filter .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper,
mat-paginator .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  padding-bottom: 0;
  margin: 0;
}
.form-grid .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper,
ui-kit-table-filter .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper,
mat-paginator .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper {
  padding: 15px 1em;
}

.mat-form-field.mat-focused:not([class*=mat-form-field-invalid]) .mat-form-field-label, .mat-form-field.mat-focused:not([class*=mat-form-field-invalid]).mat-primary .mat-select-arrow {
  color: #303c42;
}

.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  margin: 10px 0;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-infix {
  border-top: 0.4em solid transparent;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-suffix,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-prefix {
  top: 0;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline {
  color: #8e979c;
}
.mat-form-field-appearance-outline:not([class*=default]).mat-form-field-disabled .mat-form-field-outline {
  color: #ced2d5;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick {
  color: #303c42;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-width: 1px;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-select-arrow-wrapper {
  transform: none;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-label-wrapper {
  top: -0.6em;
  padding-top: 0.6em;
}

.mat-paginator-page-size-select.mat-form-field-appearance-outline:not([class*=default]) {
  margin: 6px 4px;
  width: 80px;
}

.gm-style .gm-style-iw {
  border-radius: 2px;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.07);
  /* stylelint-disable-line function-allowed-list */
}

.map-container {
  width: 100% !important;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #303c42;
  font-size: 0.875rem;
  line-height: 1.57;
}

a {
  color: #c91019;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

h1:not([class*=default]),
.h1,
h2:not([class*=default]),
.h2,
h3:not([class*=default]),
.h3,
h4:not([class*=default]),
.h4,
h5:not([class*=default]),
.h5,
h6:not([class*=default]),
.h6 {
  line-height: 1;
}

h1:not([class*=default]),
.h1 {
  font-size: 1.875rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
.h1 {
    font-size: 1.5;
    font-weight: 400;
  }
}

h2:not([class*=default]),
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h2:not([class*=default]),
.h2 {
    font-size: 1.5rem;
  }
}

h3:not([class*=default]),
.h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  h3:not([class*=default]),
.h3 {
    font-size: 1rem;
  }
}

h4:not([class*=default]),
.h4 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h4:not([class*=default]),
.h4 {
    font-size: 0.875rem;
  }
}

h5:not([class*=default]),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;
}
@media screen and (max-width: 599px) {
  h5:not([class*=default]),
.h5 {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*=default]),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: #5d6a72;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #4b575f;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*=default]),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 500;
}

.mat-card-title:not([class*=default]) {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 20px;
}
@media screen and (max-width: 599px) {
  .mat-card-title:not([class*=default]) {
    font-size: 0.875rem;
  }
}

.mat-card-subtitle:not([class*=default]) {
  font-size: inherit;
  color: #4b575f;
  margin-bottom: 20px;
}

.mat-card-title:not(:first-child):not([class*=default]),
.mat-card-subtitle:not(:first-child):not([class*=default]) {
  margin-top: -15px;
}

.mat-card-image:first-child:not([class*=default]) {
  margin-top: -20px;
}

.mat-card-actions:not([class*=default]) {
  display: grid;
}

.mat-paginator:not([class*=default]) {
  background: none;
}

.mat-badge-content:not([class*=default]) {
  width: auto;
  padding: 0 5px;
  font-weight: normal;
}

.mat-badge-medium .mat-badge-content:not([class*=default]) {
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  min-width: 10px;
}

.mat-badge-small .mat-badge-content:not([class*=default]) {
  height: 15px;
  line-height: 15px;
  border-radius: 15px;
  min-width: 15px;
  box-sizing: border-box;
}

.mat-input-element {
  caret-color: #303c42;
}

mat-datepicker-toggle {
  position: relative;
  top: 0.25em;
}

.loading.mat-progress-bar {
  bottom: 4px;
  margin-bottom: -4px;
}
.loading .mat-progress-bar-buffer {
  background-color: #bad5f8;
}
.loading .mat-progress-bar-fill::after {
  background-color: #176be6;
}

#freshworks-container #launcher-frame {
  z-index: 1000 !important;
}

.mat-tooltip {
  color: #f8f9fb;
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-tooltip {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
h1:not([class*=default]),
.h1 {
  margin-top: 40px;
  margin-bottom: 20px;
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
.h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

h2:not([class*=default]),
.h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}

h3:not([class*=default]),
.h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

h4:not([class*=default]),
.h4 {
  margin-top: 30px;
  margin-bottom: 5px;
}

h5:not([class*=default]),
.h5 {
  margin-top: 20px;
  margin-bottom: 0px;
}

h6:not([class*=default]),
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media print {
  body {
    height: auto;
  }

  .pd-table td,
.mat-table td {
    color: #303c42 !important;
  }
  .pd-table .mat-form-field:not([class*=default]),
.mat-table .mat-form-field:not([class*=default]) {
    width: 100%;
  }
  .pd-table .mat-form-field:not([class*=default]) .mat-form-field-infix,
.mat-table .mat-form-field:not([class*=default]) .mat-form-field-infix {
    width: 0;
  }

  ds-breadcrumbs {
    display: none;
  }

  .mat-form-field-appearance-outline:not([class*=default]) {
    /* remove borders to make inputs look like text */
    /* no border, no padding needed here*/
    /* make all labels float to give a continously look */
    /* default text color on input - even if disabled = readonly */
    /* hide interactive elements */
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline .mat-form-field-outline-gap,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline .mat-form-field-outline-end {
    border: none;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-flex {
    padding: 0;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-label {
    transform: translateY(-1.34375em) !important;
  }
  .mat-form-field-appearance-outline:not([class*=default]).mat-form-field-disabled .mat-form-field-outline {
    color: #303c42;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-input-element:disabled,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: #303c42;
  }
  .mat-form-field-appearance-outline:not([class*=default]) mat-datepicker-toggle,
.mat-form-field-appearance-outline:not([class*=default]) .mat-select-arrow-wrapper,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-required-marker {
    display: none;
  }

  .mat-card {
    box-shadow: none;
    border: 1px solid #303c42;
  }

  .mat-snack-bar-container {
    display: none !important;
  }
}
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
.info,
table.mat-table .info {
  background: linear-gradient(180deg, #3c88ec, #1a73e9);
  color: white;
}
@media print {
  .info,
table.mat-table .info {
    background: linear-gradient(180deg, #3c88ec, #1a73e9);
    -webkit-print-color-adjust: exact;
  }
}

.info-text,
table.mat-table .info-text {
  color: #1a73e9 !important;
}

.success,
table.mat-table .success {
  background: linear-gradient(180deg, #1ba041, #179738);
  color: white;
}
@media print {
  .success,
table.mat-table .success {
    background: linear-gradient(180deg, #1ba041, #179738);
    -webkit-print-color-adjust: exact;
  }
}

.success-text,
table.mat-table .success-text {
  color: #1ba041 !important;
}

.warning,
table.mat-table .warning {
  background: linear-gradient(180deg, #ea8826, #e67300);
  color: white;
}
@media print {
  .warning,
table.mat-table .warning {
    background: linear-gradient(180deg, #ea8826, #e67300);
    -webkit-print-color-adjust: exact;
  }
}

.warning-text,
table.mat-table .warning-text {
  color: #e67300 !important;
}

.error,
table.mat-table .error {
  background: linear-gradient(180deg, #ff3542, #ff2d39);
  color: white;
}
@media print {
  .error,
table.mat-table .error {
    background: linear-gradient(180deg, #ff3542, #ff2d39);
    -webkit-print-color-adjust: exact;
  }
}

.error-text,
table.mat-table .error-text {
  color: #ff3b49 !important;
  font-weight: 500;
}

table.mat-table .error-text,
table.mat-table .warning-text,
table.mat-table .success-text,
table.mat-table .info-text {
  font-weight: 500;
}

.neutral,
table.mat-table .neutral {
  background: linear-gradient(180deg, #758087, #5d6a72);
  color: white;
}
@media print {
  .neutral,
table.mat-table .neutral {
    background: linear-gradient(180deg, #758087, #5d6a72);
    -webkit-print-color-adjust: exact;
  }
}

mat-icon[mini-icon] {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.ribbon {
  position: relative;
  overflow: hidden;
}
.ribbon:after {
  content: " ";
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #d1343c, #c91019);
}
@media print {
  .ribbon:after {
    background: linear-gradient(180deg, #d1343c, #c91019);
    -webkit-print-color-adjust: exact;
  }
}

.neutral-ribbon {
  position: relative;
  overflow: hidden;
}
.neutral-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #758087, #5d6a72);
}
@media print {
  .neutral-ribbon:after {
    background: linear-gradient(180deg, #758087, #5d6a72);
    -webkit-print-color-adjust: exact;
  }
}

.info-ribbon {
  position: relative;
  overflow: hidden;
}
.info-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #5f9df0, #3c88ec);
}
@media print {
  .info-ribbon:after {
    background: linear-gradient(180deg, #5f9df0, #3c88ec);
    -webkit-print-color-adjust: exact;
  }
}

.success-ribbon {
  position: relative;
  overflow: hidden;
}
.success-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #41b563, #1fa848);
}
@media print {
  .success-ribbon:after {
    background: linear-gradient(180deg, #41b563, #1fa848);
    -webkit-print-color-adjust: exact;
  }
}

.warning-ribbon {
  position: relative;
  overflow: hidden;
}
.warning-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #f3b980, #ee9d4d);
}
@media print {
  .warning-ribbon:after {
    background: linear-gradient(180deg, #f3b980, #ee9d4d);
    -webkit-print-color-adjust: exact;
  }
}

.error-ribbon {
  position: relative;
  overflow: hidden;
}
.error-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #ff5864, #ff3b49);
}
@media print {
  .error-ribbon:after {
    background: linear-gradient(180deg, #ff5864, #ff3b49);
    -webkit-print-color-adjust: exact;
  }
}

.pd-loading {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.pd-loading div {
  animation: pd-loading 1.5s ease-in-out infinite;
  transform-origin: 40px 40px;
}
.pd-loading div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
}

.pd-loading div:nth-child(1) {
  animation-delay: -0.045s;
}
.pd-loading div:nth-child(1):after {
  background: #efb7ba;
}

.pd-loading div:nth-child(2) {
  animation-delay: -0.09s;
}
.pd-loading div:nth-child(2):after {
  background: #e4888c;
}

.pd-loading div:nth-child(3) {
  animation-delay: -0.135s;
}
.pd-loading div:nth-child(3):after {
  background: #d9585e;
}

.pd-loading div:nth-child(4) {
  animation-delay: -0.18s;
}
.pd-loading div:nth-child(4):after {
  background: #d1343c;
}

.pd-loading div:nth-child(5) {
  animation-delay: -0.225s;
}
.pd-loading div:nth-child(5):after {
  background: #c91019;
}

.pd-loading div:nth-child(6) {
  animation-delay: -0.27s;
}
.pd-loading div:nth-child(6):after {
  background: #c30e16;
}

.pd-loading div:nth-child(7) {
  animation-delay: -0.315s;
}
.pd-loading div:nth-child(7):after {
  background: #bc0c12;
}

.pd-loading div:nth-child(8) {
  animation-delay: -0.36s;
}
.pd-loading div:nth-child(8):after {
  background: #b5090e;
}

.pd-loading div:nth-child(9) {
  animation-delay: -0.405s;
}
.pd-loading div:nth-child(9):after {
  background: #a90508;
}

.pd-loading div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.pd-loading div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.pd-loading div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.pd-loading div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.pd-loading div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.pd-loading div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.pd-loading div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.pd-loading div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes pd-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-max-height {
  max-height: 100%;
}

.auto-overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.relative-position {
  position: relative;
}

.scroll-container-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-container-horizontal {
  overflow-y: hidden;
  overflow-x: auto;
}

.hidden-scollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
.hidden-scollbar::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.flex-page-container {
  min-height: 0;
}
.flex-page-container::after {
  content: "";
  height: 40px;
  display: block;
  width: 100%;
}

ds-full-page-body {
  min-height: 0;
  flex: 1 1 auto;
}
ds-full-page-body::after {
  content: "";
  height: 40px;
  display: block;
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.padding {
  padding: 20px;
}

.padding-top {
  padding-top: 20px;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.mini-padding {
  padding: 10px;
}

.no-min-height {
  min-height: 0;
}

.normal-white-space {
  white-space: normal;
}

@font-face {
  font-family: PalfingerIcons;
  src: url("/assets/fonts/PalfingerIcons.eot");
  src: url("/assets/fonts/PalfingerIcons.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PalfingerIcons.woff2") format("woff2"), url("/assets/fonts/PalfingerIcons.woff") format("woff"), url("/assets/fonts/PalfingerIcons.ttf") format("truetype"), url("/assets/fonts/PalfingerIcons.svg#PalfingerIcons") format("svg");
  font-style: normal;
  font-weight: 400;
}
.pd {
  display: inline-block;
  transform: translate(0, 0);
  text-rendering: auto;
  font: normal normal 400 14px/1 PalfingerIcons;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.pd-lg {
  vertical-align: -15%;
  line-height: 0.75em;
  font-size: 1.33333333em;
}

.pd-2x {
  font-size: 2em;
}

.pd-3x {
  font-size: 3em;
}

.pd-4x {
  font-size: 4em;
}

.pd-5x {
  font-size: 5em;
}

.pd-fw {
  width: 1.28571429em;
  text-align: center;
}

.pd-access-denied::before {
  content: "\ea01";
}

.pd-alerts::before {
  content: "\ea02";
}

.pd-axis-y-rotate::before {
  content: "\ea03";
}

.pd-axis-z-rotate::before {
  content: "\ea04";
}

.pd-carrier::before {
  content: "\ea05";
}

.pd-carrier-management::before {
  content: "\ea06";
}

.pd-code-reader::before {
  content: "\ea07";
}

.pd-crane-functions::before {
  content: "\ea08";
}

.pd-crane-lifting-power::before {
  content: "\ea09";
}

.pd-crane-outreach::before {
  content: "\ea0a";
}

.pd-documents::before {
  content: "\ea0b";
}

.pd-engine::before {
  content: "\ea0c";
}

.pd-equipment-status::before {
  content: "\ea0d";
}

.pd-filter::before {
  content: "\ea0e";
}

.pd-fleet-monitor::before {
  content: "\ea0f";
}

.pd-fleet-status::before {
  content: "\ea10";
}

.pd-geofence::before {
  content: "\ea11";
}

.pd-height-limitation::before {
  content: "\ea12";
}

.pd-job-planning::before {
  content: "\ea13";
}

.pd-local_shipping_outlined::before {
  content: "\ea14";
}

.pd-location::before {
  content: "\ea15";
}

.pd-location-status::before {
  content: "\ea16";
}

.pd-lubricant-1::before {
  content: "\ea17";
}

.pd-lubricant-2::before {
  content: "\ea18";
}

.pd-lubricant-3::before {
  content: "\ea19";
}

.pd-lubricant-4::before {
  content: "\ea1a";
}

.pd-lubricant-5::before {
  content: "\ea1b";
}

.pd-mail-read-and-prio::before {
  content: "\ea1c";
}

.pd-maintenance-checklist::before {
  content: "\ea1d";
}

.pd-market-model::before {
  content: "\ea1e";
}

.pd-no-search-result::before {
  content: "\ea1f";
}

.pd-oh-crane::before {
  content: "\ea20";
}

.pd-oh-dailyPTO::before {
  content: "\ea21";
}

.pd-oh-emergencyModeActivation::before {
  content: "\ea22";
}

.pd-oh-flyjib::before {
  content: "\ea23";
}

.pd-oh-hookloader::before {
  content: "\ea24";
}

.pd-oh-leverNotNeutral::before {
  content: "\ea25";
}

.pd-oh-loadingOperations::before {
  content: "\ea26";
}

.pd-oh-manualOverride::before {
  content: "\ea27";
}

.pd-oh-mechv::before {
  content: "\ea28";
}

.pd-oh-platforms::before {
  content: "\ea29";
}

.pd-oh-taillift::before {
  content: "\ea2a";
}

.pd-oh-tippingOperations::before {
  content: "\ea2b";
}

.pd-oh-tool::before {
  content: "\ea2c";
}

.pd-oh-winch::before {
  content: "\ea2d";
}

.pd-oh-winch_rtc::before {
  content: "\ea2e";
}

.pd-oh-workingBasket::before {
  content: "\ea2f";
}

.pd-oh-workingBasket_leverNotNeutral::before {
  content: "\ea30";
}

.pd-pac-online::before {
  content: "\ea31";
}

.pd-palcode::before {
  content: "\ea32";
}

.pd-palshow-web::before {
  content: "\ea33";
}

.pd-palsoft-updates::before {
  content: "\ea34";
}

.pd-route::before {
  content: "\ea35";
}

.pd-sentiment-dissatisfied::before {
  content: "\ea36";
}

.pd-sentiment-satisfied::before {
  content: "\ea37";
}

.pd-service::before {
  content: "\ea38";
}

.pd-service-cockpit::before {
  content: "\ea39";
}

.pd-service-partner-search::before {
  content: "\ea3a";
}

.pd-software-options::before {
  content: "\ea3b";
}

.pd-stability-chart::before {
  content: "\ea3c";
}

.pd-stabilizer-left::before {
  content: "\ea3d";
}

.pd-stabilizer-left-disabled::before {
  content: "\ea3e";
}

.pd-stabilizer-right::before {
  content: "\ea3f";
}

.pd-stabilizer-right-disabled::before {
  content: "\ea40";
}

.pd-telematic::before {
  content: "\ea41";
}

.pd-telematic-doctor::before {
  content: "\ea42";
}

.pd-telematic-lab::before {
  content: "\ea43";
}

.pd-utilization::before {
  content: "\ea44";
}

.pd {
  text-align: center;
  font-size: 24px;
}

/* roboto-100 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto Regular"), local("Roboto-Regular"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-900 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Black"), local("Roboto-Black"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "PalfingerHandelVersalien";
  src: url("/assets/fonts/palfingerhandelversalien-webfont.woff2") format("woff2"), url("/assets/fonts/palfingerhandelversalien-webfont.woff") format("woff");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("/assets/materialicons/MaterialIcons-Regular.woff2") format("woff2"), url("/assets/materialicons/MaterialIcons-Regular.woff") format("woff"), url("/assets/materialicons/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons, .mat-select-arrow::before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.palfinger-font:not([class*=default]) {
  font-family: PalfingerHandelVersalien;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #303c42;
  font-size: 0.875rem;
  line-height: 1.57;
}

a {
  color: #c91019;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

h1:not([class*=default]),
.h1,
h2:not([class*=default]),
.h2,
h3:not([class*=default]),
.h3,
h4:not([class*=default]),
.h4,
h5:not([class*=default]),
.h5,
h6:not([class*=default]),
.h6 {
  line-height: 1;
}

h1:not([class*=default]),
.h1 {
  font-size: 1.875rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
.h1 {
    font-size: 1.5;
    font-weight: 400;
  }
}

h2:not([class*=default]),
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h2:not([class*=default]),
.h2 {
    font-size: 1.5rem;
  }
}

h3:not([class*=default]),
.h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  h3:not([class*=default]),
.h3 {
    font-size: 1rem;
  }
}

h4:not([class*=default]),
.h4 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h4:not([class*=default]),
.h4 {
    font-size: 0.875rem;
  }
}

h5:not([class*=default]),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;
}
@media screen and (max-width: 599px) {
  h5:not([class*=default]),
.h5 {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*=default]),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: #5d6a72;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #4b575f;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*=default]),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 500;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #f8f9fb;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
  padding: 5px;
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

.mat-drawer {
  border-right: none;
}

.mat-drawer-container {
  background: none;
  color: inherit;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
  /* stylelint-disable-line function-allowed-list */
}

.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-card:not([class*=mat-elevation-z]),
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  /* stylelint-disable-line function-allowed-list */
}

.mat-fab:not([class*=mat-elevation-z]),
.mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]),
.mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.22);
  /* stylelint-disable-line function-allowed-list */
}

.mat-snack-bar-container:not([class*=mat-elevation-z]) {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z0 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.02);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z1 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z2 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.04);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z3 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z4 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.06);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z5 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.07);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z6 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.08);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z7 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.09);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z8 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z9 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.11);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z10 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.12);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z11 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.13);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z12 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.14);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z13 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.15);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z14 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.16);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z15 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.17);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z16 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.18);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z17 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.19);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z18 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.2);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z19 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.21);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.22);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.23);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.24);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z23 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.25);
  /* stylelint-disable-line function-allowed-list */
}

.mat-elevation-z24 {
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.26);
  /* stylelint-disable-line function-allowed-list */
}

.mat-card:not([class*=default]) {
  box-sizing: border-box;
  padding: 20px;
}

.mat-card .mat-card-image {
  width: calc(100% + 40px);
  margin: 0 -20px 20px -20px;
}

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-stroked-button.mat-button-base,
.mat-raised-button.mat-button-base {
  line-height: 40px;
  padding: 0 40px;
}

.mat-stroked-button.mat-button-base:not([class*=default]) {
  padding: 0 calc(40px - 1px);
}

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-raised-button.mat-button-base,
.mat-stroked-button.mat-button-base {
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-transform: none;
  border-radius: 2px;
}
.mat-button.mat-button-base[disabled],
.mat-flat-button.mat-button-base[disabled],
.mat-raised-button.mat-button-base[disabled],
.mat-stroked-button.mat-button-base[disabled] {
  opacity: 0.8;
}
.mat-button.mat-button-base mat-spinner,
.mat-flat-button.mat-button-base mat-spinner,
.mat-raised-button.mat-button-base mat-spinner,
.mat-stroked-button.mat-button-base mat-spinner {
  display: inline-block;
}

.mat-raised-button.mat-accent:focus,
.mat-raised-button.mat-accent:hover {
  transition: none;
  background: #55626a;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: #303c42;
}

.mat-stroked-button.mat-button-base {
  border-color: inherit;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}
.mat-flat-button.mat-accent mat-spinner circle,
.mat-raised-button.mat-accent mat-spinner circle,
.mat-fab.mat-accent mat-spinner circle,
.mat-mini-fab.mat-accent mat-spinner circle {
  stroke: white;
}
.mat-flat-button.mat-accent[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-fab.mat-accent[disabled],
.mat-mini-fab.mat-accent[disabled] {
  color: white;
  opacity: 0.4;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}
.mat-flat-button.mat-primary mat-spinner circle,
.mat-raised-button.mat-primary mat-spinner circle,
.mat-fab.mat-primary mat-spinner circle,
.mat-mini-fab.mat-primary mat-spinner circle {
  stroke: white;
}
.mat-flat-button.mat-primary[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-fab.mat-primary[disabled],
.mat-mini-fab.mat-primary[disabled] {
  color: white;
  opacity: 0.4;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background: linear-gradient(75deg, #d54300, #df6000);
}
@media print {
  .mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
    background: linear-gradient(75deg, #d54300, #df6000);
    -webkit-print-color-adjust: exact;
  }
}
.mat-flat-button.mat-warn mat-spinner circle,
.mat-raised-button.mat-warn mat-spinner circle,
.mat-fab.mat-warn mat-spinner circle,
.mat-mini-fab.mat-warn mat-spinner circle {
  stroke: white;
}
.mat-flat-button.mat-warn[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-fab.mat-warn[disabled],
.mat-mini-fab.mat-warn[disabled] {
  color: white;
  opacity: 0.4;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #f8f9fb;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
  padding: 5px;
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
.mat-dialog-container:not([class*=default]) {
  border-radius: 2px;
  padding: 20px;
  background: #f8f9fb;
}

.mat-dialog-content:not([class*=default]) {
  margin: 0 -20px;
  padding: 0 20px 20px;
}

.mat-dialog-title:not([class*=default]) {
  margin: 0 0 20px !important;
  display: block;
}

.mat-dialog-actions:not([class*=default]) {
  background: #ecedee;
  padding: 10px 20px !important;
  margin: -20px;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.mat-dialog-actions:not([class*=default]) .mat-button-base + .mat-button-base {
  margin-left: 10px;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
table {
  border-spacing: 0px;
}

table.pd-table:not([class*=default]),
table.mat-table:not([class*=default]) {
  border-spacing: 0;
  background: none;
}

table.mat-table thead tr,
table.pd-table thead tr,
tr.mat-header-row:not([class*=default]) {
  height: 60px;
}

table.mat-table tbody tr,
table.mat-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-row:not([class*=default]),
tr.mat-footer-row:not([class*=default]) {
  height: 50px;
}

table.mat-table thead th,
table.pd-table thead th,
th.mat-header-cell:not([class*=default]) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
}
table.mat-table thead th:not([class*=text]),
table.pd-table thead th:not([class*=text]),
th.mat-header-cell:not([class*=default]):not([class*=text]) {
  text-align: left;
}

table.mat-table thead th,
table.mat-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-header-cell:not([class*=default]),
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  padding: 10px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #ecedee;
}
table.mat-table thead th:not([class*=white-space]),
table.mat-table td:not([class*=white-space]),
table.pd-table thead th:not([class*=white-space]),
table.pd-table td:not([class*=white-space]),
th.mat-header-cell:not([class*=default]):not([class*=white-space]),
td.mat-cell:not([class*=default]):not([class*=white-space]),
td.mat-footer-cell:not([class*=default]):not([class*=white-space]) {
  white-space: nowrap;
}

table.mat-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-table td,
table.pd-table td,
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  color: #5d6a72;
}

table.mat-table tr th:first-child,
table.mat-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-header-cell:first-child:not([class*=default]),
td.mat-cell:first-child:not([class*=default]),
td.mat-footer-cell:first-child:not([class*=default]) {
  padding-left: 20px;
}

table.mat-table th:last-child:not([class*=default]),
table.mat-table td:last-child:not([class*=default]),
table.pd-table th:last-child:not([class*=default]),
table.pd-table td:last-child:not([class*=default]) {
  padding-right: 20px;
  white-space: nowrap;
}

table.mat-table tbody tr:last-child td,
table.pd-table tbody tr:last-child td,
tr.mat-row:last-child:not([class*=default]) td,
tr.mat-header-row:last-child:not([class*=default]) td,
tr.mat-footer-row:last-child:not([class*=default]) td {
  border-width: 0;
}

tr.mat-footer-row:last-child td.mat-table-sticky:not([class*=default]),
tr.mat-footer-row:last-child td.pd-table-sticky:not([class*=default]) {
  border-width: 1px 0 0;
}

.mat-list-base .mat-list-item {
  font-size: inherit;
}

.mat-list-base {
  padding-top: 20px;
}

.mat-list-base .mat-list-item:not([class*=default]),
.mat-list-base .mat-list-option:not([class*=default]) {
  height: 40px;
}

.mat-list-base .mat-list-item .mat-list-item-content:not([class*=default]),
.mat-list-base .mat-list-option .mat-list-item-content:not([class*=default]) {
  padding: 0 20px;
}

.mat-select-arrow:not([class*=default]) {
  border: none;
  margin-bottom: -12px;
  margin-top: -12px;
  height: auto;
  width: auto;
}

.mat-select-arrow::before {
  content: "expand_more";
}

.mat-select-search-inside-mat-option:not([class*=default]) button.mat-select-search-clear:not([class*=default]) {
  padding: 0;
  border-radius: 50%;
  top: 1px;
}

.mat-select-panel:not([class*=default]) {
  max-width: 80%;
}

::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: unset;
}

::ng-deep .mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: #55626a;
}

.mat-chip.mat-standard-chip {
  color: white;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #f8f9fb;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
  padding: 5px;
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

.mat-stepper-horizontal:not([class*=default]),
.mat-stepper-vertical:not([class*=default]) {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f8f9fb;
}

.mat-horizontal-stepper-header-container {
  flex: 0 0 auto;
}

.mat-horizontal-stepper-header:not([class*=default]) {
  flex: 1;
  height: 60px;
  justify-content: center;
  padding: 0;
  overflow: visible;
}

.mat-horizontal-content-container:not([class*=default]) {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  flex: 1 1 0;
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .mat-horizontal-content-container:not([class*=default]) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 599px) {
  .mat-horizontal-content-container:not([class*=default]) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.mat-horizontal-stepper-content:not([class*=default]) {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  transition-property: padding-right;
  height: 100%;
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .mat-horizontal-stepper-content:not([class*=default]) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 599px) {
  .mat-horizontal-stepper-content:not([class*=default]) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mat-horizontal-stepper-content:not([class*=default]) h2 {
  margin-top: 40px;
}
.mat-horizontal-stepper-content:not([class*=default]):not(:empty)::after {
  content: "";
  height: 40px;
  display: block;
  width: 100%;
}

.mat-horizontal-stepper-content[aria-expanded=false] {
  padding: 0;
}

.mat-step-optional {
  display: none;
}

.mat-step-header .mat-step-label:not([class*=default]) {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(75deg, #758087, #8e979c);
  overflow: visible;
  cursor: default;
}
@media print {
  .mat-step-header .mat-step-label:not([class*=default]) {
    background: linear-gradient(75deg, #758087, #8e979c);
    -webkit-print-color-adjust: exact;
  }
}
.mat-step-header .mat-step-label:not([class*=default])::before {
  content: "";
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 30px solid #758087;
  border-bottom: 30px solid #758087;
  border-left: 10px solid transparent;
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
  cursor: pointer;
  font-weight: unset;
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before {
  border-top: 30px solid #303c42;
  border-bottom: 30px solid #303c42;
}
@media screen and (max-width: 959px) {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
    background: linear-gradient(180deg, #303c42, #4b575f);
  }
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before {
    display: none;
  }
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
  cursor: pointer;
  font-weight: unset;
  background: linear-gradient(75deg, #a90508, #bc0c12);
}
@media print {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
    background: linear-gradient(75deg, #a90508, #bc0c12);
    -webkit-print-color-adjust: exact;
  }
}
.mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before {
  border-top: 30px solid #a90508;
  border-bottom: 30px solid #a90508;
}
@media screen and (max-width: 959px) {
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
    background: linear-gradient(180deg, #a90508, #bc0c12);
  }
  .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before {
    display: none;
  }
}

.mat-horizontal-stepper-header:first-of-type .mat-step-label::before {
  display: none;
}

.mat-step-icon:not([class*=default]) {
  display: none;
}

.mat-stepper-horizontal-line:not([class*=default]) {
  flex: 0;
  min-width: 0;
  margin: 0;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
table {
  border-spacing: 0px;
}

table.pd-table:not([class*=default]),
table.mat-table:not([class*=default]) {
  border-spacing: 0;
  background: none;
}

table.mat-table thead tr,
table.pd-table thead tr,
tr.mat-header-row:not([class*=default]) {
  height: 60px;
}

table.mat-table tbody tr,
table.mat-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-row:not([class*=default]),
tr.mat-footer-row:not([class*=default]) {
  height: 50px;
}

table.mat-table thead th,
table.pd-table thead th,
th.mat-header-cell:not([class*=default]) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
}
table.mat-table thead th:not([class*=text]),
table.pd-table thead th:not([class*=text]),
th.mat-header-cell:not([class*=default]):not([class*=text]) {
  text-align: left;
}

table.mat-table thead th,
table.mat-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-header-cell:not([class*=default]),
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  padding: 10px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #ecedee;
}
table.mat-table thead th:not([class*=white-space]),
table.mat-table td:not([class*=white-space]),
table.pd-table thead th:not([class*=white-space]),
table.pd-table td:not([class*=white-space]),
th.mat-header-cell:not([class*=default]):not([class*=white-space]),
td.mat-cell:not([class*=default]):not([class*=white-space]),
td.mat-footer-cell:not([class*=default]):not([class*=white-space]) {
  white-space: nowrap;
}

table.mat-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-table td,
table.pd-table td,
td.mat-cell:not([class*=default]),
td.mat-footer-cell:not([class*=default]) {
  color: #5d6a72;
}

table.mat-table tr th:first-child,
table.mat-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-header-cell:first-child:not([class*=default]),
td.mat-cell:first-child:not([class*=default]),
td.mat-footer-cell:first-child:not([class*=default]) {
  padding-left: 20px;
}

table.mat-table th:last-child:not([class*=default]),
table.mat-table td:last-child:not([class*=default]),
table.pd-table th:last-child:not([class*=default]),
table.pd-table td:last-child:not([class*=default]) {
  padding-right: 20px;
  white-space: nowrap;
}

table.mat-table tbody tr:last-child td,
table.pd-table tbody tr:last-child td,
tr.mat-row:last-child:not([class*=default]) td,
tr.mat-header-row:last-child:not([class*=default]) td,
tr.mat-footer-row:last-child:not([class*=default]) td {
  border-width: 0;
}

tr.mat-footer-row:last-child td.mat-table-sticky:not([class*=default]),
tr.mat-footer-row:last-child td.pd-table-sticky:not([class*=default]) {
  border-width: 1px 0 0;
}

.mat-expansion-panel:not([class*=default]) {
  border-bottom: 1px solid #ecedee;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header {
  font-size: inherit;
  padding: 1px 20px;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header.mat-expanded {
  padding: 3px 20px;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header .mat-content {
  margin-right: 20px;
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-body {
  padding: 0 20px 20px;
}

.mat-expansion-panel:last-of-type,
.mat-expansion-panel.mat-expanded {
  border-bottom: 0;
}

mat-card .mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
  border-bottom: 1px solid #ecedee;
}
mat-card .mat-accordion .mat-expansion-panel:first-of-type {
  border-top: 1px solid #ecedee;
}
mat-card .mat-accordion .mat-expansion-panel {
  border-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
.mat-snack-bar-container:not([class*=default]) {
  position: absolute;
  background: white;
  border-radius: 2px;
  color: unset;
  max-width: calc(100% - 56px) !important;
  margin: 0;
  bottom: 20px;
  left: 80px;
}
.mat-snack-bar-container:not([class*=default]) ds-text-indicator {
  overflow: hidden;
}
.mat-snack-bar-container:not([class*=default]) ds-text-indicator span {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 599px) {
  .mat-snack-bar-container:not([class*=default]) {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }
}

.mat-snack-bar-container.snackbar-with-component {
  padding: 0;
}

.mat-form-field-wrapper:not([class*=default]) {
  padding-bottom: 20px;
}

.pd-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper,
.mat-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper {
  margin-top: 1em;
}
.pd-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper,
.mat-table .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  padding: 10px 0;
  margin: 0;
}

.form-grid .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper,
ui-kit-table-filter .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper,
mat-paginator .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  padding-bottom: 0;
  margin: 0;
}
.form-grid .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper,
ui-kit-table-filter .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper,
mat-paginator .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-subscript-wrapper {
  padding: 15px 1em;
}

.mat-form-field.mat-focused:not([class*=mat-form-field-invalid]) .mat-form-field-label, .mat-form-field.mat-focused:not([class*=mat-form-field-invalid]).mat-primary .mat-select-arrow {
  color: #303c42;
}

.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  margin: 10px 0;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-infix {
  border-top: 0.4em solid transparent;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-suffix,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-prefix {
  top: 0;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline {
  color: #8e979c;
}
.mat-form-field-appearance-outline:not([class*=default]).mat-form-field-disabled .mat-form-field-outline {
  color: #ced2d5;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick {
  color: #303c42;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-width: 1px;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-select-arrow-wrapper {
  transform: none;
}
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-label-wrapper {
  top: -0.6em;
  padding-top: 0.6em;
}

.mat-paginator-page-size-select.mat-form-field-appearance-outline:not([class*=default]) {
  margin: 6px 4px;
  width: 80px;
}

.gm-style .gm-style-iw {
  border-radius: 2px;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.07);
  /* stylelint-disable-line function-allowed-list */
}

.map-container {
  width: 100% !important;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #303c42;
  font-size: 0.875rem;
  line-height: 1.57;
}

a {
  color: #c91019;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

h1:not([class*=default]),
.h1,
h2:not([class*=default]),
.h2,
h3:not([class*=default]),
.h3,
h4:not([class*=default]),
.h4,
h5:not([class*=default]),
.h5,
h6:not([class*=default]),
.h6 {
  line-height: 1;
}

h1:not([class*=default]),
.h1 {
  font-size: 1.875rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
.h1 {
    font-size: 1.5;
    font-weight: 400;
  }
}

h2:not([class*=default]),
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h2:not([class*=default]),
.h2 {
    font-size: 1.5rem;
  }
}

h3:not([class*=default]),
.h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  h3:not([class*=default]),
.h3 {
    font-size: 1rem;
  }
}

h4:not([class*=default]),
.h4 {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h4:not([class*=default]),
.h4 {
    font-size: 0.875rem;
  }
}

h5:not([class*=default]),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;
}
@media screen and (max-width: 599px) {
  h5:not([class*=default]),
.h5 {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*=default]),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: #5d6a72;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #4b575f;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*=default]),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 500;
}

.mat-card-title:not([class*=default]) {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 20px;
}
@media screen and (max-width: 599px) {
  .mat-card-title:not([class*=default]) {
    font-size: 0.875rem;
  }
}

.mat-card-subtitle:not([class*=default]) {
  font-size: inherit;
  color: #4b575f;
  margin-bottom: 20px;
}

.mat-card-title:not(:first-child):not([class*=default]),
.mat-card-subtitle:not(:first-child):not([class*=default]) {
  margin-top: -15px;
}

.mat-card-image:first-child:not([class*=default]) {
  margin-top: -20px;
}

.mat-card-actions:not([class*=default]) {
  display: grid;
}

.mat-paginator:not([class*=default]) {
  background: none;
}

.mat-badge-content:not([class*=default]) {
  width: auto;
  padding: 0 5px;
  font-weight: normal;
}

.mat-badge-medium .mat-badge-content:not([class*=default]) {
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  min-width: 10px;
}

.mat-badge-small .mat-badge-content:not([class*=default]) {
  height: 15px;
  line-height: 15px;
  border-radius: 15px;
  min-width: 15px;
  box-sizing: border-box;
}

.mat-input-element {
  caret-color: #303c42;
}

mat-datepicker-toggle {
  position: relative;
  top: 0.25em;
}

.loading.mat-progress-bar {
  bottom: 4px;
  margin-bottom: -4px;
}
.loading .mat-progress-bar-buffer {
  background-color: #bad5f8;
}
.loading .mat-progress-bar-fill::after {
  background-color: #176be6;
}

#freshworks-container #launcher-frame {
  z-index: 1000 !important;
}

.mat-tooltip {
  color: #f8f9fb;
  background: linear-gradient(75deg, #303c42, #4b575f);
}
@media print {
  .mat-tooltip {
    background: linear-gradient(75deg, #303c42, #4b575f);
    -webkit-print-color-adjust: exact;
  }
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
h1:not([class*=default]),
.h1 {
  margin-top: 40px;
  margin-bottom: 20px;
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
.h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

h2:not([class*=default]),
.h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}

h3:not([class*=default]),
.h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

h4:not([class*=default]),
.h4 {
  margin-top: 30px;
  margin-bottom: 5px;
}

h5:not([class*=default]),
.h5 {
  margin-top: 20px;
  margin-bottom: 0px;
}

h6:not([class*=default]),
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media print {
  body {
    height: auto;
  }

  .pd-table td,
.mat-table td {
    color: #303c42 !important;
  }
  .pd-table .mat-form-field:not([class*=default]),
.mat-table .mat-form-field:not([class*=default]) {
    width: 100%;
  }
  .pd-table .mat-form-field:not([class*=default]) .mat-form-field-infix,
.mat-table .mat-form-field:not([class*=default]) .mat-form-field-infix {
    width: 0;
  }

  ds-breadcrumbs {
    display: none;
  }

  .mat-form-field-appearance-outline:not([class*=default]) {
    /* remove borders to make inputs look like text */
    /* no border, no padding needed here*/
    /* make all labels float to give a continously look */
    /* default text color on input - even if disabled = readonly */
    /* hide interactive elements */
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline .mat-form-field-outline-gap,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-outline .mat-form-field-outline-end {
    border: none;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-flex {
    padding: 0;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-label {
    transform: translateY(-1.34375em) !important;
  }
  .mat-form-field-appearance-outline:not([class*=default]).mat-form-field-disabled .mat-form-field-outline {
    color: #303c42;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-input-element:disabled,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: #303c42;
  }
  .mat-form-field-appearance-outline:not([class*=default]) mat-datepicker-toggle,
.mat-form-field-appearance-outline:not([class*=default]) .mat-select-arrow-wrapper,
.mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-required-marker {
    display: none;
  }

  .mat-card {
    box-shadow: none;
    border: 1px solid #303c42;
  }

  .mat-snack-bar-container {
    display: none !important;
  }
}
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: uppercase;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nowrap-text {
  white-space: nowrap;
}

.inherit-color {
  color: inherit;
}

div[pair] {
  width: 100%;
  display: flex;
  flex-direction: column;
}
div[pair] *[title] {
  color: #4b575f;
  font-weight: normal;
}
div[pair] *:last-child {
  font-weight: 500;
}

table {
  width: 100%;
}
table .mat-table-sticky {
  background: #ffffff;
}
table tbody td.mat-cell.mat-table-sticky {
  width: 1%;
  box-shadow: 20px 10px 20px 2px rgba(0, 0, 0, 0.03);
  /* stylelint-disable-line function-whitelist */
}
table tbody td.mat-cell.mat-table-sticky:last-child {
  padding-right: 10px;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.03);
  /* stylelint-disable-line function-allowed-list */
}
table thead th.mat-header-cell.mat-table-sticky:last-child {
  padding-right: 10px;
}
table thead th.mat-header-cell.mat-table-sticky:last-child:not([class*=text-]) {
  text-align: center;
}
table td.mat-action-cell,
table td.mat-cell.mat-action-cell:not([class*=default]),
table mat-form-field {
  color: #303c42;
}
table th mat-hint {
  font-size: 75%;
  margin-bottom: -1em;
  display: block;
  line-height: 1;
}
table th.mat-header-cell.text-right {
  text-align: right;
}
table th.mat-header-cell.text-right .mat-sort-header-content {
  text-align: right;
}
table th.mat-header-cell.text-center {
  text-align: center;
}
table th.mat-header-cell.text-center .mat-sort-header-content {
  text-align: center;
}
table[list] {
  font-weight: 500;
}
table[list] > span {
  display: table-caption;
  width: 100%;
  margin-bottom: 10px;
}
table[list] td,
table[list] th {
  vertical-align: top;
  padding-bottom: 10px;
}
table[list] td:first-child,
table[list] th:first-child {
  padding-right: 10px;
}
table[list] tr > td:nth-child(odd) {
  font-weight: normal;
  color: #4b575f;
  white-space: nowrap;
  padding-right: 10px;
}
table[dense] tr.mat-row:not([class*=default]),
table[dense] tr.mat-header-row:not([class*=default]),
table[dense] tr.mat-footer-row tr.mat-row:not([class*=default]) {
  height: auto;
}
table[dense] th.mat-header-cell:not([class*=default]),
table[dense] td.mat-cell:not([class*=default]),
table[dense] td.mat-footer-cell:not([class*=default]) {
  padding: 10px;
}
table[dense] .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-wrapper {
  padding: 0;
}
table[dense] .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-flex {
  padding: 0 0.5em 0 0.5em;
}
table[dense] .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-infix {
  padding: 0.3em 0;
}
table[dense] .mat-form-field-appearance-outline:not([class*=default]) .mat-form-field-label {
  top: 1em;
}
table[dense] .mat-form-field-appearance-outline:not([class*=default]).mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, table[dense] .mat-form-field-appearance-outline:not([class*=default]).mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.8em) scale(0.75);
}
table .min-width-cell {
  width: 1%;
}

.mat-sort-header-content {
  display: block !important;
}

.text-right .mat-sort-header-content {
  text-align: right;
}

.table-column-group-header {
  text-align: center !important;
}
.table-column-group-header:not(:first-child) {
  border-left-width: 1px !important;
}

.ds-table-group-border {
  border-left-width: 1px !important;
}

.mat-card:not([class*=default]) {
  box-sizing: border-box;
  padding: 20px;
}

.mat-card .mat-card-image {
  width: calc(100% + 40px);
  margin: 0 -20px 20px -20px;
}

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-stroked-button.mat-button-base,
.mat-raised-button.mat-button-base {
  line-height: 40px;
  padding: 0 40px;
}

.mat-stroked-button.mat-button-base:not([class*=default]) {
  padding: 0 calc(40px - 1px);
}

mat-icon[button-icon] {
  margin-right: 10px;
}

[mat-button][page-action]:not([class*=default]) {
  padding: 0 20px;
}

[mat-button][back-button]:not([class*=default]) {
  margin: 20px -40px;
  text-transform: inherit;
}

.pending-spinner {
  margin-left: -30px;
  position: absolute !important;
  /* vertical */
  /* horizontal */
  /* transform */
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  bottom: unset;
  left: unset;
  right: unset;
}

/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/* These media-query mixins have to be in accordance with fxLayout breadkpoints
see https://github.com/angular/flex-layout/wiki/Responsive-API
*/
.stepper-with-sidebar-content {
  padding-right: 360px;
}
@media screen and (max-width: 959px) {
  .stepper-with-sidebar-content {
    padding-right: unset;
  }
}

ds-responsive-stepper .mat-stepper-horizontal {
  height: auto;
}
ds-responsive-stepper .mat-stepper-horizontal .mat-horizontal-stepper-content {
  padding: 0;
}

@media screen and (max-width: 959px) {
  .mat-horizontal-stepper-header-container:not([class*=default]) {
    align-items: stretch;
    flex-direction: column;
    display: block;
  }

  .mat-stepper-horizontal-line {
    display: none;
  }
}
.background-primary-50 {
  background: #f9e2e3;
  -webkit-print-color-adjust: exact;
}

.background-primary-100 {
  background: #efb7ba;
  -webkit-print-color-adjust: exact;
}

.background-primary-200 {
  background: #e4888c;
  -webkit-print-color-adjust: exact;
}

.background-primary-300 {
  background: #d9585e;
  -webkit-print-color-adjust: exact;
}

.background-primary-400 {
  background: #d1343c;
  -webkit-print-color-adjust: exact;
}

.background-primary-500 {
  background: #c91019;
  -webkit-print-color-adjust: exact;
}

.background-primary-600 {
  background: #c30e16;
  -webkit-print-color-adjust: exact;
}

.background-primary-700 {
  background: #bc0c12;
  -webkit-print-color-adjust: exact;
}

.background-primary-800 {
  background: #b5090e;
  -webkit-print-color-adjust: exact;
}

.background-primary-900 {
  background: #a90508;
  -webkit-print-color-adjust: exact;
}

.background-primary-A100 {
  background: #ffd4d4;
  -webkit-print-color-adjust: exact;
}

.background-primary-A200 {
  background: #ffa1a1;
  -webkit-print-color-adjust: exact;
}

.background-primary-A400 {
  background: #ff6e6f;
  -webkit-print-color-adjust: exact;
}

.background-primary-A700 {
  background: #ff5456;
  -webkit-print-color-adjust: exact;
}

.background-primary-default {
  background: #c91019;
  -webkit-print-color-adjust: exact;
}

.background-primary-lighter {
  background: #efb7ba;
  -webkit-print-color-adjust: exact;
}

.background-primary-darker {
  background: #bc0c12;
  -webkit-print-color-adjust: exact;
}

.background-primary-text {
  background: #c91019;
  -webkit-print-color-adjust: exact;
}

.background-primary-default-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-lighter-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-primary-darker-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-50-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-primary-100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-primary-200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-primary-300-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-500-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-600-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-800-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-900-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-A100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-primary-A200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-primary-A400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-A700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-primary-contrast-contrast {
  -webkit-print-color-adjust: exact;
}

.background-success-50 {
  background: #e4f5e9;
  -webkit-print-color-adjust: exact;
}

.background-success-100 {
  background: #bce5c8;
  -webkit-print-color-adjust: exact;
}

.background-success-200 {
  background: #8fd4a4;
  -webkit-print-color-adjust: exact;
}

.background-success-300 {
  background: #62c27f;
  -webkit-print-color-adjust: exact;
}

.background-success-400 {
  background: #41b563;
  -webkit-print-color-adjust: exact;
}

.background-success-500 {
  background: #1fa848;
  -webkit-print-color-adjust: exact;
}

.background-success-600 {
  background: #1ba041;
  -webkit-print-color-adjust: exact;
}

.background-success-700 {
  background: #179738;
  -webkit-print-color-adjust: exact;
}

.background-success-800 {
  background: #128d30;
  -webkit-print-color-adjust: exact;
}

.background-success-900 {
  background: #0a7d21;
  -webkit-print-color-adjust: exact;
}

.background-success-A100 {
  background: #adffba;
  -webkit-print-color-adjust: exact;
}

.background-success-A200 {
  background: #7aff8f;
  -webkit-print-color-adjust: exact;
}

.background-success-A400 {
  background: #47ff64;
  -webkit-print-color-adjust: exact;
}

.background-success-A700 {
  background: #2dff4e;
  -webkit-print-color-adjust: exact;
}

.background-success-default {
  background: #1fa848;
  -webkit-print-color-adjust: exact;
}

.background-success-lighter {
  background: #bce5c8;
  -webkit-print-color-adjust: exact;
}

.background-success-darker {
  background: #179738;
  -webkit-print-color-adjust: exact;
}

.background-success-text {
  background: #1fa848;
  -webkit-print-color-adjust: exact;
}

.background-success-default-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-lighter-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-success-darker-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-50-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-success-100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-success-200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-success-300-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-500-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-600-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-800-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-900-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-A100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-success-A200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-success-A400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-A700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-success-contrast-contrast {
  -webkit-print-color-adjust: exact;
}

.background-error-50 {
  background: #ffe7e9;
  -webkit-print-color-adjust: exact;
}

.background-error-100 {
  background: #ffc4c8;
  -webkit-print-color-adjust: exact;
}

.background-error-200 {
  background: #ff9da4;
  -webkit-print-color-adjust: exact;
}

.background-error-300 {
  background: #ff7680;
  -webkit-print-color-adjust: exact;
}

.background-error-400 {
  background: #ff5864;
  -webkit-print-color-adjust: exact;
}

.background-error-500 {
  background: #ff3b49;
  -webkit-print-color-adjust: exact;
}

.background-error-600 {
  background: #ff3542;
  -webkit-print-color-adjust: exact;
}

.background-error-700 {
  background: #ff2d39;
  -webkit-print-color-adjust: exact;
}

.background-error-800 {
  background: #ff2631;
  -webkit-print-color-adjust: exact;
}

.background-error-900 {
  background: #ff1921;
  -webkit-print-color-adjust: exact;
}

.background-error-A100 {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-A200 {
  background: #fffdfd;
  -webkit-print-color-adjust: exact;
}

.background-error-A400 {
  background: #ffcacc;
  -webkit-print-color-adjust: exact;
}

.background-error-A700 {
  background: #ffb1b3;
  -webkit-print-color-adjust: exact;
}

.background-error-default {
  background: #ff3b49;
  -webkit-print-color-adjust: exact;
}

.background-error-lighter {
  background: #ffc4c8;
  -webkit-print-color-adjust: exact;
}

.background-error-darker {
  background: #ff2d39;
  -webkit-print-color-adjust: exact;
}

.background-error-text {
  background: #ff3b49;
  -webkit-print-color-adjust: exact;
}

.background-error-default-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-lighter-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-error-darker-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-50-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-error-100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-error-200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-error-300-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-500-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-600-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-800-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-900-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-A100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-error-A200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-error-A400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-A700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-error-contrast-contrast {
  -webkit-print-color-adjust: exact;
}

.background-info-50 {
  background: #e4eefc;
  -webkit-print-color-adjust: exact;
}

.background-info-100 {
  background: #bad5f8;
  -webkit-print-color-adjust: exact;
}

.background-info-200 {
  background: #8db9f4;
  -webkit-print-color-adjust: exact;
}

.background-info-300 {
  background: #5f9df0;
  -webkit-print-color-adjust: exact;
}

.background-info-400 {
  background: #3c88ec;
  -webkit-print-color-adjust: exact;
}

.background-info-500 {
  background: #1a73e9;
  -webkit-print-color-adjust: exact;
}

.background-info-600 {
  background: #176be6;
  -webkit-print-color-adjust: exact;
}

.background-info-700 {
  background: #1360e3;
  -webkit-print-color-adjust: exact;
}

.background-info-800 {
  background: #0f56df;
  -webkit-print-color-adjust: exact;
}

.background-info-900 {
  background: #0843d9;
  -webkit-print-color-adjust: exact;
}

.background-info-A100 {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-A200 {
  background: #cfdaff;
  -webkit-print-color-adjust: exact;
}

.background-info-A400 {
  background: #9cb3ff;
  -webkit-print-color-adjust: exact;
}

.background-info-A700 {
  background: #83a0ff;
  -webkit-print-color-adjust: exact;
}

.background-info-default {
  background: #1a73e9;
  -webkit-print-color-adjust: exact;
}

.background-info-lighter {
  background: #bad5f8;
  -webkit-print-color-adjust: exact;
}

.background-info-darker {
  background: #1360e3;
  -webkit-print-color-adjust: exact;
}

.background-info-text {
  background: #1a73e9;
  -webkit-print-color-adjust: exact;
}

.background-info-default-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-lighter-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-info-darker-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-50-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-info-100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-info-200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-info-300-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-500-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-600-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-800-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-900-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-A100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-info-A200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-info-A400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-A700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-info-contrast-contrast {
  -webkit-print-color-adjust: exact;
}

.background-warning-50 {
  background: #fceee0;
  -webkit-print-color-adjust: exact;
}

.background-warning-100 {
  background: #f8d5b3;
  -webkit-print-color-adjust: exact;
}

.background-warning-200 {
  background: #f3b980;
  -webkit-print-color-adjust: exact;
}

.background-warning-300 {
  background: #ee9d4d;
  -webkit-print-color-adjust: exact;
}

.background-warning-400 {
  background: #ea8826;
  -webkit-print-color-adjust: exact;
}

.background-warning-500 {
  background: #e67300;
  -webkit-print-color-adjust: exact;
}

.background-warning-600 {
  background: #e36b00;
  -webkit-print-color-adjust: exact;
}

.background-warning-700 {
  background: #df6000;
  -webkit-print-color-adjust: exact;
}

.background-warning-800 {
  background: #db5600;
  -webkit-print-color-adjust: exact;
}

.background-warning-900 {
  background: #d54300;
  -webkit-print-color-adjust: exact;
}

.background-warning-A100 {
  background: #fffdfc;
  -webkit-print-color-adjust: exact;
}

.background-warning-A200 {
  background: #ffd7c9;
  -webkit-print-color-adjust: exact;
}

.background-warning-A400 {
  background: #ffb096;
  -webkit-print-color-adjust: exact;
}

.background-warning-A700 {
  background: #ff9d7d;
  -webkit-print-color-adjust: exact;
}

.background-warning-default {
  background: #e67300;
  -webkit-print-color-adjust: exact;
}

.background-warning-lighter {
  background: #f8d5b3;
  -webkit-print-color-adjust: exact;
}

.background-warning-darker {
  background: #df6000;
  -webkit-print-color-adjust: exact;
}

.background-warning-text {
  background: #e67300;
  -webkit-print-color-adjust: exact;
}

.background-warning-default-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-lighter-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-warning-darker-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-50-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-warning-100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-warning-200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-warning-300-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-500-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-600-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-800-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-900-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-A100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-warning-A200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-warning-A400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-A700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-warning-contrast-contrast {
  -webkit-print-color-adjust: exact;
}

.background-neutral-10 {
  background: #f8f9fb;
  -webkit-print-color-adjust: exact;
}

.background-neutral-50 {
  background: #ecedee;
  -webkit-print-color-adjust: exact;
}

.background-neutral-100 {
  background: #ced2d5;
  -webkit-print-color-adjust: exact;
}

.background-neutral-200 {
  background: #aeb5b9;
  -webkit-print-color-adjust: exact;
}

.background-neutral-300 {
  background: #8e979c;
  -webkit-print-color-adjust: exact;
}

.background-neutral-400 {
  background: #758087;
  -webkit-print-color-adjust: exact;
}

.background-neutral-500 {
  background: #5d6a72;
  -webkit-print-color-adjust: exact;
}

.background-neutral-600 {
  background: #55626a;
  -webkit-print-color-adjust: exact;
}

.background-neutral-700 {
  background: #4b575f;
  -webkit-print-color-adjust: exact;
}

.background-neutral-800 {
  background: #414d55;
  -webkit-print-color-adjust: exact;
}

.background-neutral-900 {
  background: #303c42;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A100 {
  background: #93d9ff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A200 {
  background: #60c7ff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A400 {
  background: #2db5ff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A700 {
  background: #14acff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-default {
  background: #5d6a72;
  -webkit-print-color-adjust: exact;
}

.background-neutral-lighter {
  background: #ced2d5;
  -webkit-print-color-adjust: exact;
}

.background-neutral-darker {
  background: #4b575f;
  -webkit-print-color-adjust: exact;
}

.background-neutral-text {
  background: #5d6a72;
  -webkit-print-color-adjust: exact;
}

.background-neutral-default-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-lighter-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-neutral-darker-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-10-contrast {
  -webkit-print-color-adjust: exact;
}

.background-neutral-50-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-neutral-100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-neutral-200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-neutral-300-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-500-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-600-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-800-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-900-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A100-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A200-contrast {
  background: #707070;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A400-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-A700-contrast {
  background: #ffffff;
  -webkit-print-color-adjust: exact;
}

.background-neutral-contrast-contrast {
  -webkit-print-color-adjust: exact;
}

.mat-expansion-panel:not([class*=default]).no-collapsed-background:not([class*=mat-expanded]) {
  background: none;
  box-shadow: none;
}
.mat-expansion-panel:not([class*=default]).no-background {
  background: none;
  box-shadow: none;
}
.mat-expansion-panel:not([class*=default]).no-padding .mat-expansion-panel-body {
  padding: 0;
}

.mat-form-field:not([class*=default]).small-min-width .mat-form-field-infix {
  width: 100px;
}

.print-only {
  display: none !important;
}

@media print {
  .print-only {
    display: initial !important;
  }

  .print-hidden {
    display: none !important;
  }
}
.spotlight {
  border: 2px solid #1a73e9;
  animation: pulse 2s infinite;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #1a73e9;
  }
  100% {
    box-shadow: 0 0 0 10px #1a73e900;
  }
}
.mat-badge-info .mat-badge-content:not([class*=default]) {
  background: #1a73e9;
}

.mat-badge-success .mat-badge-content:not([class*=default]) {
  background: #1fa848;
}

.mat-dialog-actions {
  min-height: unset !important;
}

.bar-chart-container {
  display: flex;
  height: 500px;
}

.chart-legend .legend-title .legend-title-text {
  display: none;
}

.pie-chart {
  display: flex;
  width: 100%;
  height: 350px;
}
.pie-chart .pie-label {
  font-size: inherit;
}

.bar-chart-stacked {
  display: flex;
  width: 100%;
  height: 150px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
}

.chart-legend ul {
  list-style: none;
  margin: 0;
}
.chart-legend ul li {
  margin-bottom: 5px;
}
.chart-legend ul li:first-child {
  font-weight: bold;
}
.chart-legend span {
  display: inline-block;
  height: 12px;
  margin-right: 10px;
  width: 30px;
}

.compact-chart-legend ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.compact-chart-legend ul li {
  list-style-type: none;
  margin-bottom: 5px;
}
.compact-chart-legend span {
  display: inline-block;
  height: 12px;
  margin-right: 10px;
  width: 30px;
}