@import 'styles/new-design/mixins/_index';
@import 'styles/new-design/variables/_index';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: mat-color($neutral, 900);
  font-size: 0.875rem;
  line-height: 1.57;
}

a {
  color: mat-color($primary);
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

h1:not([class*='default']),
.h1,
h2:not([class*='default']),
.h2,
h3:not([class*='default']),
.h3,
h4:not([class*='default']),
.h4,
h5:not([class*='default']),
.h5,
h6:not([class*='default']),
.h6 {
  line-height: 1;
}

h1:not([class*='default']),
.h1 {
  font-size: 1.875rem;
  font-weight: 500;

  @include media-xs {
    font-size: 1.5;
    font-weight: 400;
  }
}

// this is used to position stepper-sidebar
$h2FontSize: 1.5rem;
$h2FontSizeXS: 1.5rem;
h2:not([class*='default']),
.h2 {
  font-size: $h2FontSize;
  font-weight: 500;

  @include media-xs {
    font-size: 1.5rem;
  }
}

h3:not([class*='default']),
.h3 {
  font-size: 1.25rem;
  font-weight: 400;

  @include media-xs {
    font-size: 1rem;
  }
}

// this is used to style mat card title
@mixin h4() {
  font-size: 1rem;
  font-weight: 500;

  @include media-xs {
    font-size: 0.875rem;
  }
}

h4:not([class*='default']),
.h4 {
  @include h4();
}

h5:not([class*='default']),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;

  @include media-xs {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*='default']),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: mat-color($neutral, 500);
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: mat-color($neutral, 700);
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*='default']),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: 500;
}
