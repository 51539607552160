@import 'styles/new-design/variables/_index';

.spotlight {
  border: 2px solid mat-color($info);
  animation: pulse 2s infinite;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 mat-color($info);
  }

  100% {
    box-shadow: 0 0 0 0.5 * $spacing #{mat-color($info) + '00'};
  }
}
