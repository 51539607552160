@import 'styles/new-design/variables/_index';

.mat-badge-content:not([class*='default']) {
  width: auto;
  padding: 0 0.25 * $spacing;
  font-weight: normal;
}

.mat-badge-medium .mat-badge-content:not([class*='default']) {
  height: $spacing;
  line-height: $spacing;
  border-radius: $spacing;
  min-width: 0.5 * $spacing;
}

.mat-badge-small .mat-badge-content:not([class*='default']) {
  height: 0.75 * $spacing;
  line-height: 0.75 * $spacing;
  border-radius: 0.75 * $spacing;
  min-width: 0.75 * $spacing;
  box-sizing: border-box;
}
