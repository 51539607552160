.print-only {
  display: none !important;
}

@media print {
  .print-only {
    display: initial !important;
  }

  .print-hidden {
    display: none !important;
  }
}
