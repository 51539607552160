@import '../variables/_index.scss';
@import '../base/overwrites/spacing.scss';

mat-icon[button-icon] {
  margin-right: 0.5 * $spacing;
}

[mat-button][page-action]:not([class*='default']) {
  padding: 0 $spacing;
}

[mat-button][back-button]:not([class*='default']) {
  margin: 1 * $spacing #{-$buttonSidePadding};
  text-transform: inherit;
}

.pending-spinner {
  margin-left: -1.5 * $spacing;
  position: absolute !important;
  @include position-absolute('center', 'none');
}
