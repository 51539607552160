@import 'styles/new-design/variables/_index';
@import 'styles/new-design/mixins/_index';

.mat-snack-bar-container:not([class*='default']) {
  position: absolute;
  background: mat-color($neutral, 'default-contrast');
  border-radius: $borderRadius;
  color: unset;
  max-width: calc(100% - #{2.8 * $spacing}) !important;
  margin: 0;
  bottom: 1 * $spacing;
  left: 4 * $spacing;

  ds-text-indicator {
    overflow: hidden;
    span {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  @include media-xs {
    left: 1 * $spacing;
    bottom: 1 * $spacing;
    right: 1 * $spacing;
  }
}

.mat-snack-bar-container.snackbar-with-component {
  padding: 0;
}
