@import '../variables';

$bodyBackground: mat-color($neutral, 10);

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: $bodyBackground;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: mat-color($neutral, 50);
  -webkit-print-color-adjust: exact;

  padding: 0.25 * $spacing;
  display: inline-block;
  font-size: 1rem;

  strong {
    font-weight: 600;
  }
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}
.bigger-font-tooltip {
  font-size: 0.8rem;
}
