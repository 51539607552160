@import '../mixins/elevation.scss';
@import '../variables/_index.scss';

table {
  width: 100%;

  // STICKY
  .mat-table-sticky {
    background: map_get($mat-palfinger-background, card);
  }

  tbody td.mat-cell.mat-table-sticky {
    width: 1%;
    @include elevation(1, right);

    &:last-child {
      padding-right: 0.5 * $spacing;
      @include elevation(1);
    }
  }

  thead th.mat-header-cell.mat-table-sticky:last-child {
    padding-right: 0.5 * $spacing;

    &:not([class*='text-']) {
      text-align: center;
    }
  }

  // INTERACTABLE
  td.mat-action-cell,
  td.mat-cell.mat-action-cell:not([class*='default']),
  mat-form-field {
    color: mat-color($neutral, 900);
  }

  // HEADER
  th {
    mat-hint {
      font-size: 75%;
      margin-bottom: -1em;
      display: block;
      line-height: 1;
    }

    &.mat-header-cell {
      &.text-right {
        text-align: right;

        .mat-sort-header-content {
          text-align: right;
        }
      }

      &.text-center {
        text-align: center;

        .mat-sort-header-content {
          text-align: center;
        }
      }
    }
  }

  // LIST
  &[list] {
    font-weight: 500;
    > span {
      display: table-caption;
      width: 100%;
      margin-bottom: 0.5 * $spacing;
    }

    td,
    th {
      vertical-align: top;
      padding-bottom: 0.5 * $spacing;

      &:first-child {
        padding-right: 0.5 * $spacing;
      }
    }

    tr > td:nth-child(odd) {
      font-weight: normal;
      color: mat-color($neutral, 700);
      white-space: nowrap;
      padding-right: 0.5 * $spacing;
    }
  }

  // DENSE
  &[dense] {
    tr.mat-row,
    tr.mat-header-row,
    tr.mat-footer-row tr.mat-row {
      &:not([class*='default']) {
        height: auto;
      }
    }
    th.mat-header-cell:not([class*='default']),
    td.mat-cell:not([class*='default']),
    td.mat-footer-cell:not([class*='default']) {
      padding: 0.5 * $spacing;
    }
    .mat-form-field-appearance-outline:not([class*='default']) {
      .mat-form-field-wrapper {
        padding: 0;
      }
      .mat-form-field-flex {
        padding: 0 0.5em 0 0.5em;
      }
      .mat-form-field-infix {
        padding: 0.3em 0;
      }

      .mat-form-field-label {
        top: 1em;
      }

      &.mat-form-field-can-float.mat-form-field-should-float
        .mat-form-field-label,
      &.mat-form-field-can-float
        .mat-input-server:focus
        + .mat-form-field-label-wrapper
        .mat-form-field-label {
        transform: translateY(-0.8em) scale(0.75);
      }
    }
  }

  // MIN WIDTH CELL
  .min-width-cell {
    width: 1%;
  }
}

.mat-sort-header-content {
  display: block !important;
}
.text-right .mat-sort-header-content {
  text-align: right;
}

.table-column-group-header {
  text-align: center !important;
  &:not(:first-child) {
    border-left-width: 1px !important;
  }
}
.ds-table-group-border {
  border-left-width: 1px !important;
}
