@import '../variables/colors.scss';

@mixin gradient($color: $neutral, $hue1: 400, $hue2: 500) {
  @include linear-gradient(
    180deg,
    mat-color($color, $hue1),
    mat-color($color, $hue2)
  );
}

@mixin inclined-gradient($color: $neutral, $hue1: 900, $hue2: 700) {
  @include linear-gradient(
    75deg,
    mat-color($color, $hue1),
    mat-color($color, $hue2)
  );
}

@mixin nav-gradient($color: $neutral) {
  @include linear-gradient(
    180deg,
    mat-color($color, 900),
    mat-color($color, 700)
  );
}

@mixin cta-gradient() {
  @include inclined-gradient();
}

@mixin skew-gradient($color1: $neutral, $color2: $neutral, $angle: -45deg) {
  @include linear-gradient($angle, $color1 50%, $color2 50%);
}

@mixin linear-gradient($angle, $color1, $color2) {
  background: linear-gradient($angle, $color1, $color2);
  @media print {
    background: linear-gradient($angle, $color1, $color2);
    -webkit-print-color-adjust: exact;
  }
}
