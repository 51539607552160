@use 'sass:math';
@import '../variables';

@mixin elevation($zValue, $direction: center, $size: $spacing, $color: black) {
  $opacity: 0.02 + 0.01 * $zValue;
  @if $direction == center {
    box-shadow: 0px
      #{0.5 *
      $size}
      #{$size}
      #{0.5 *
      $size}
      rgba($color, $opacity); /* stylelint-disable-line function-allowed-list */
  } @else if $direction == right {
    $sizeTenth: math.div($size, 10);
    box-shadow: $size
      #{0.5 *
      $size}
      #{$size}
      #{$sizeTenth}
      rgba($color, $opacity); /* stylelint-disable-line function-whitelist */
  } @else if $direction == top {
    box-shadow: 0px
      #{-1 *
      $size}
      #{$size}
      #{0.25 *
      $size}
      rgba($color, $opacity); /* stylelint-disable-line function-allowed-list */
  } @else {
    @error 'Unknown direction #{$direction}. For defaut set center as direction attribute';
  }
}
