@import 'styles/new-design/mixins/elevation';
@import 'styles/new-design/variables/_index';

.gm-style .gm-style-iw {
  border-radius: $borderRadius;
  @include elevation(5);
}

.map-container {
  width: 100% !important;
}
