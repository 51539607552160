.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-max-height {
  max-height: 100%;
}

.auto-overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.relative-position {
  position: relative;
}

.scroll-container-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll-container-horizontal {
  overflow-y: hidden;
  overflow-x: auto;
}
.hidden-scollbar {
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.flex-page-container {
  min-height: 0;
  &::after {
    content: '';
    height: $pageBottomSpacing;
    display: block;
    width: 100%;
  }
}

ds-full-page-body {
  min-height: 0;
  flex: 1 1 auto;

  &::after {
    content: '';
    height: $pageBottomSpacing;
    display: block;
    width: 100%;
  }
}
