@import 'styles/new-design/base/layout.scss';
@import 'styles/new-design/variables/_index';
@import 'styles/new-design/mixins/_index';

.mat-dialog-container:not([class*='default']) {
  border-radius: $borderRadius;
  padding: $spacing;
  background: $bodyBackground;
}

.mat-dialog-content:not([class*='default']) {
  margin: 0 -#{$spacing};
  padding: 0 $spacing $spacing;
}
.mat-dialog-title:not([class*='default']) {
  margin: 0 0 $spacing !important;
  display: block;
}

.mat-dialog-actions:not([class*='default']) {
  @include actions-container();
  padding: 0.5 * $spacing $spacing !important;
  margin: -$spacing;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  .mat-button-base + .mat-button-base {
    margin-left: 0.5 * $spacing;
  }
}
