@import 'styles/new-design/variables/_index';
@import 'styles/new-design/mixins';

$stepperSidebarSize: 18 * $spacing;

.stepper-with-sidebar-content {
  padding-right: $stepperSidebarSize;

  @include media-lt-md {
    padding-right: unset;
  }
}

ds-responsive-stepper {
  .mat-stepper-horizontal {
    height: auto;

    .mat-horizontal-stepper-content {
      padding: 0;
    }
  }
}

@include media-lt-md {
  .mat-horizontal-stepper-header-container:not([class*='default']) {
    align-items: stretch;
    flex-direction: column;
    display: block;
  }

  .mat-stepper-horizontal-line {
    display: none;
  }
}
