.pd-loading {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  div {
    animation: pd-loading 1.5s ease-in-out infinite;
    transform-origin: 40px 40px;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: -4px 0 0 -4px;
    }
  }
}
@for $i from 1 through 9 {
  .pd-loading div:nth-child(#{$i}) {
    animation-delay: -$i * 0.045s;
    &:after {
      background: mat-color($primary, ($i * 100));
    }
  }
}
.pd-loading div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.pd-loading div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.pd-loading div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.pd-loading div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.pd-loading div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.pd-loading div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.pd-loading div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.pd-loading div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes pd-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
