@import '../variables';

$pallets: (
  'primary': $primary,
  'success': $success,
  'error': $error,
  'info': $info,
  'warning': $warning,
  'neutral': $neutral,
);

@each $palletName, $pallet in $pallets {
  @each $property, $value in $pallet {
    .background-#{$palletName}-#{$property} {
      @if $property != 'contrast' {
        background: $value;
        color: mat-contrast($pallet, $value);
        -webkit-print-color-adjust: exact;
      }
    }
  }
}
