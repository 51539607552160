@import 'styles/new-design/variables/_index';
.mat-form-field-wrapper:not([class*='default']) {
  padding-bottom: 1 * $spacing;
}

.pd-table,
.mat-table {
  .mat-form-field-appearance-outline:not([class*='default']) {
    .mat-form-field-subscript-wrapper {
      margin-top: 1em;
    }
    .mat-form-field-wrapper {
      padding: 0.5 * $spacing 0;
      margin: 0;
    }
  }
}

.form-grid,
ui-kit-table-filter,
mat-paginator {
  .mat-form-field-appearance-outline:not([class*='default']) {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;
    }
    .mat-form-field-subscript-wrapper {
      padding: 15px 1em;
    }
  }
}

.mat-form-field.mat-focused:not([class*='mat-form-field-invalid']) {
  .mat-form-field-label,
  &.mat-primary .mat-select-arrow {
    color: mat-color($neutral, 900);
  }
}

.mat-form-field-appearance-outline:not([class*='default']) {
  .mat-form-field-wrapper {
    margin: 0.5 * $spacing 0;
  }

  .mat-form-field-infix {
    border-top: 0.4em solid transparent;
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    top: 0;
  }

  .mat-form-field-outline {
    color: mat-color($neutral, 300);
  }
  &.mat-form-field-disabled .mat-form-field-outline {
    color: mat-color($neutral, 100);
  }
  .mat-form-field-outline-thick {
    color: mat-color($neutral, 900);

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 1px;
    }
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-form-field-label-wrapper {
    top: -0.6em;
    padding-top: 0.6em;
  }
}

.mat-paginator-page-size-select.mat-form-field-appearance-outline:not([class*='default']) {
  margin: 6px 4px;
  width: 4 * $spacing;
}
