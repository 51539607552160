@use 'sass:math';
@import '../../variables';
@import './table';

// angular sets the height of the header to 48px via code,
// but we want it to be 2.5 * spacing (3.5 expanded), so we set the top and bottom
// padding to give us this total height.
$headerPaddingTopBottom: math.div(2.5 * $spacing - 48, 2);
$headerPaddingTopBottomExpanded: math.div(3.5 * $spacing - 64, 2);

.mat-expansion-panel:not([class*='default']) {
  border-bottom: 1px solid $tableBorderColor;

  .mat-expansion-panel-header {
    font-size: inherit;
    padding: $headerPaddingTopBottom $spacing;

    &.mat-expanded {
      padding: $headerPaddingTopBottomExpanded $spacing;
    }

    .mat-content {
      margin-right: 1 * $spacing;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 $spacing $spacing;
  }
}

.mat-expansion-panel:last-of-type,
.mat-expansion-panel.mat-expanded {
  border-bottom: 0;
}

mat-card .mat-accordion {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
    border-bottom: 1px solid $tableBorderColor;
  }

  .mat-expansion-panel:first-of-type {
    border-top: 1px solid $tableBorderColor;
  }

  .mat-expansion-panel {
    border-radius: 0;
  }

  .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }
}
