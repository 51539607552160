@import '../variables';
@import '../mixins/_index';

h1:not([class*='default']),
.h1 {
  margin-top: 2 * $spacing;
  margin-bottom: 1 * $spacing;
  @include media-xs {
    margin-top: 0.5 * $spacing;
    margin-bottom: 0.5 * $spacing;
  }
}

// this is used to position stepper-sidebar
$h2MarginTop: 2 * $spacing;
$h2MarginBottom: 1 * $spacing;
h2:not([class*='default']),
.h2 {
  margin-top: $h2MarginTop;
  margin-bottom: $h2MarginBottom;
}

h3:not([class*='default']),
.h3 {
  margin-top: 1.5 * $spacing;
  margin-bottom: 0.5 * $spacing;
}

h4:not([class*='default']),
.h4 {
  margin-top: 1.5 * $spacing;
  margin-bottom: 0.25 * $spacing;
}

h5:not([class*='default']),
.h5 {
  margin-top: 1 * $spacing;
  margin-bottom: 0 * $spacing;
}

h6:not([class*='default']),
.h6 {
  margin-top: 0.5 * $spacing;
  margin-bottom: 0.5 * $spacing;
}
