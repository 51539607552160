@import 'styles/new-design/variables';

// Style for stacked bar charts and cards
.bar-chart-container {
  display: flex;
  height: 500px;
}

.chart-legend .legend-title .legend-title-text {
  display: none;
}

.pie-chart {
  display: flex;
  width: 100%;
  height: 350px;

  .pie-label {
    font-size: inherit;
  }
}

.bar-chart-stacked {
  display: flex;
  width: 100%;
  height: 150px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
}

.chart-legend {
  ul {
    list-style: none;
    margin: 0;

    li {
      margin-bottom: 5px;

      &:first-child {
        font-weight: bold;
      }
    }
  }

  span {
    display: inline-block;
    height: 12px;
    margin-right: 10px;
    width: 30px;
  }
}

.compact-chart-legend {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin-bottom: 5px;
    }
  }

  span {
    display: inline-block;
    height: 12px;
    margin-right: 10px;
    width: 30px;
  }
}
