@media print {
  body {
    height: auto;
  }
  .pd-table,
  .mat-table {
    td {
      color: mat-color($neutral, 900) !important;
    }
    .mat-form-field:not([class*='default']) {
      width: 100%;
      .mat-form-field-infix {
        width: 0;
      }
    }
  }

  ds-breadcrumbs {
    display: none;
  }

  .mat-form-field-appearance-outline:not([class*='default']) {
    /* remove borders to make inputs look like text */
    .mat-form-field-outline {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border: none;
      }
    }

    /* no border, no padding needed here*/
    .mat-form-field-flex {
      padding: 0;
    }

    /* make all labels float to give a continously look */
    .mat-form-field-label {
      transform: translateY(-1.34375em) !important;
    }

    /* default text color on input - even if disabled = readonly */
    &.mat-form-field-disabled .mat-form-field-outline {
      color: mat-color($neutral, 900);
    }
    .mat-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled
      .mat-form-field-infix::after {
      color: mat-color($neutral, 900);
    }

    /* hide interactive elements */
    mat-datepicker-toggle,
    .mat-select-arrow-wrapper,
    .mat-form-field-required-marker {
      display: none;
    }
  }

  .mat-card {
    box-shadow: none;
    border: 1px solid mat-color($neutral, 900);
  }

  .mat-snack-bar-container {
    display: none !important;
  }
}
