@import '../variables/colors.scss';
@import './gradients.scss';

@mixin box-ribbon($color: $primary, $hue1: 400, $hue2: 500) {
  position: relative;
  overflow: hidden;
  &:after {
    content: ' ';
    position: absolute;
    width: 4px;
    left: 0;
    top: 0;
    bottom: 0;
    @include gradient($color, $hue1, $hue2);
  }
}
