.ribbon {
  @include box-ribbon();
}

.neutral-ribbon {
  @include box-ribbon($neutral);
}

.info-ribbon {
  @include box-ribbon($info, 300, 400);
}

.success-ribbon {
  @include box-ribbon($success);
}

.warning-ribbon {
  @include box-ribbon($warning, 200, 300);
}

.error-ribbon {
  @include box-ribbon($error);
}
