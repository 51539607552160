@import '~@angular/material/theming';
@import 'styles/new-design/variables/colors.scss';
@include mat-core();

.alternate-surface,
mat-card.alternate-surface,
mat-expansion-panel.alternate-surface {
  background: map_get($neutral, 50) !important;
}

@function create-palfinger-theme($primary, $accent, $warn) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $mat-palfinger-foreground,
    background: $mat-palfinger-background
  );
}

$theme: create-palfinger-theme($primary, $neutral, $warning);
@include angular-material-theme($theme);

// import down here to overwrite angular
@import 'styles/new-design/base/_index';
@import 'styles/new-design/components/_index';
