@mixin position-absolute($vertical: center, $horizontal: center) {
  $top: unset;
  $bottom: unset;
  $left: unset;
  $right: unset;

  /* vertical */
  @if $vertical == center {
    $top: 50%;
  } @else if $vertical == top {
    $top: 0;
  } @else if $vertical == bottom {
    $bottom: 0;
  }

  /* horizontal */
  @if $horizontal == center {
    $left: 50%;
  } @else if $horizontal == left {
    $left: 0;
  } @else if $horizontal == right {
    $right: 0;
  }

  /* transform */
  @if $horizontal == center and $vertical == center {
    transform: translateX(-50%) translateY(-50%);
  } @else {
    @if $vertical == center {
      transform: translateY(-50%);
    } @else if $horizontal == center {
      transform: translateX(-50%);
    }
  }

  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}
